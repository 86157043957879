<template>
    <div>
        <div class="botnav">
            <div v-if="this.page == 'userinfo'">
                <div class="row">
                    <div class="col-3 link-botnav active" @click="goToUserInfo"> <b-icon icon="person-fill" aria-hidden="true"></b-icon> <div style="font-size:12px">Account</div></div>
                    <div class="col-3 link-botnav" @click="goToHistory"> <b-icon icon="clock-fill" aria-hidden="true"></b-icon> <div style="font-size:12px">Orders</div></div>
                    <div class="col-3 link-botnav" @click="goToExport"><b-icon icon="file-earmark-arrow-down-fill" aria-hidden="true"></b-icon><div style="font-size:12px">Export</div></div>
                    <div class="col-3 link-botnav" @click="goToSetting"> <b-icon icon="gear-fill" aria-hidden="true"></b-icon> <div style="font-size:12px">Settings</div></div>
                </div>
            </div>
            <div v-else-if="this.page == 'history'">
                <div class="row">
                    <div class="col-3 link-botnav" @click="goToUserInfo"><b-icon icon="person-fill" aria-hidden="true"></b-icon><div style="font-size:12px">Account</div></div>
                    <div class="col-3 active link-botnav" @click="goToHistory"><b-icon icon="clock-fill" aria-hidden="true"></b-icon><div style="font-size:12px">Orders</div></div>
                    <div class="col-3 link-botnav" @click="goToExport"><b-icon icon="file-earmark-arrow-down-fill" aria-hidden="true"></b-icon><div style="font-size:12px">Export</div></div>
                    <div class="col-3 link-botnav" @click="goToSetting"><b-icon icon="gear-fill" aria-hidden="true"></b-icon> <div style="font-size:12px">Settings</div></div>
                </div>
            </div>
            <div v-else-if="this.page == 'exporthistory'">
                <div class="row">
                    <div class="col-3 link-botnav" @click="goToUserInfo"><b-icon icon="person-fill" aria-hidden="true"></b-icon><div style="font-size:12px">Account</div></div>
                    <div class="col-3 link-botnav" @click="goToHistory"><b-icon icon="clock-fill" aria-hidden="true"></b-icon><div style="font-size:12px">Orders</div></div>
                    <div class="col-3 active link-botnav" @click="goToExport"><b-icon icon="file-earmark-arrow-down-fill" aria-hidden="true"></b-icon><div style="font-size:12px">Export </div></div>
                    <div class="col-3 link-botnav" @click="goToSetting"><b-icon icon="gear-fill" aria-hidden="true"></b-icon> <div style="font-size:12px">Settings</div></div>
                </div>
            </div>
            <div v-else>
                <div class="row">
                    <div class="col-3 link-botnav" @click="goToUserInfo"><b-icon icon="person-fill" aria-hidden="true"></b-icon><div style="font-size:12px">Account</div></div>
                    <div class="col-3 link-botnav" @click="goToHistory"><b-icon icon="clock-fill" aria-hidden="true"></b-icon><div style="font-size:12px">Orders</div></div>
                    <div class="col-3 link-botnav" @click="goToExport"><b-icon icon="file-earmark-arrow-down-fill" aria-hidden="true"></b-icon><div style="font-size:12px">Export</div></div>
                    <div class="col-3 active link-botnav" @click="goToSetting" ><b-icon icon="gear-fill" aria-hidden="true"></b-icon> <div style="font-size:12px">Settings</div></div>
                </div>
            </div>
            
        </div>
    </div>
</template>

<script>
export default {
    name:"BottomNavBar",
    props:['page'],
    created(){
        console.log(this.page);
    },
    methods:{
        goToUserInfo(){
            this.$router.push("UserInfo");
        },
        goToHistory(){
            this.$router.push("OrderHistory");
        },
        goToSetting(){
            this.$router.push("UserSettings");
        },
        goToExport(){
            this.$router.push("exporthistory");
        }
    }
}
</script>

<style>
    .botnav {
        overflow: hidden;
        background-color: #336C8E;
        position: fixed;
        bottom: 0;
        width: 100%;
    }

    .botnav div.link-botnav {
        float: left;
        display: block;
        color: white;
        text-align: center;
        padding: 14px 20px;
        text-decoration: none;
        width: 33.3%;
    }

    .botnav div.link-botnav:hover {
        background:  white;
        color: #336C8E;
        text-decoration: none;
        font-weight: bold;

    }

    .botnav div.link-botnav.active {
        background-color: #336C8E;
        color: white;
        font-weight: bold;
    }
</style>