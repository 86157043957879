import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import { faHistory } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './assets/css/main.css'
//import VModal from 'vue-js-modal'
import * as VueGoogleMaps from 'vue2-google-maps'
import Vuelidate from 'vuelidate'
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap';
import i18n from './i18n'

Vue.component('vue-bootstrap-typeahead', VueBootstrapTypeahead)
Vue.component('vue-typeahead-bootstrap', VueTypeaheadBootstrap)
Vue.use(Vuelidate)
library.add(faUser)
library.add(faHistory)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.use(VueToast);
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
//Vue.use(VModal)
  
Sentry.init({
  Vue,
  dsn: "https://a1b057a64b234ac184dcf4c68caaec07@o592330.ingest.sentry.io/5807825",
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
  tracingOptions:{
    trackComponents:true,
  }
});

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyCnPyYhvmSixevpGuHdNJ1T_G9OKRnbF-4',
    libraries: 'places', 
  },})

Vue.config.productionTip = false

// router.beforeEach((to, from, next) => {
//   let language = to.params.lang;
//   if(!language){
//     language = 'en';
//   }

//   i18n.locale = language;
//   next();
// })

new Vue({
  i18n,
  router,
  render: h => h(App)
}).$mount('#app')
