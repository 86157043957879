<template>
    <div>
        <Navbar/>
        <div class="userSettings">
            <div class="container">
                <div class="row">
                    <div class="col-md-3 d-none d-md-block">
                        <Sidebar/>
                    </div>
                    <div class="col ml-3">
                        <div class="mt-5">
                            <div class="">
                                <h2>User Settings</h2> 
                            </div>
                            <br>

                            <div class="row">
                                <div class="col-md" style="margin-bottom:20px;">
                                    <!-- <div style="visibility:hidden;">d</div> -->
                                    <button type="button" class="button-blue" v-b-modal="'gmap-modal'" style="width:100%">{{$t('Click here to point the location')}}</button>
                                    <!-- <button type="button" class="button-blue" @click="doCheckLocation(index)">Check Location</button> -->
                                </div>
                                <br>

                                <div class="col-md-12">
                                    <div class="row">
                                        <div class="col-md">
                                            <div>{{$t('Address (Locality/Street) (Required)')}}</div>
                                            <input type="text" class="form-control input-address-delivery" v-model="alamat" placeholder="ex: Jalan Kartini Raya" >
                                        </div>
                                        <div class="col-md">
                                            <div>{{$t('House/Floor/Unit Number (Required)')}}</div>
                                            <input type="text" class="form-control input-address-delivery" v-model="apartemen" placeholder="Ex: Lt 2 No.12" >
                                        </div>
                                    </div>
                                    <br>
                                    
                                    <div class="row">
                                        <div class="col-md">
                                            <div>{{$t('State (Required)')}}</div>
                                            <select class="form-control selectbox-address-delivery" @change="getCity(provinsi)"  aria-label="Default select example" v-model="provinsi">
                                                <!-- <option value="Select State">Select State</option> -->
                                                <option v-for="dataProvice in dataProvinces" :key="dataProvice.id" :value="dataProvice.province">{{ dataProvice.province }}</option>
                                            </select>
                                        </div>
                                        <div class="col-md">
                                            <div>{{$t('City (Required)')}}</div>
                                            <select class="form-control selectbox-address-delivery"  aria-label="Default select example" v-model="kota">
                                                <!-- <option value="Select City">Select City</option> -->
                                                <option v-for="dataCity in dataCitys" :key="dataCity.id" :value="dataCity.city">{{ dataCity.city }}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <br>

                                    <div class="row">
                                        <div class="col-md">
                                            <div>{{$t('Postal Code (Required)')}} </div>
                                            <input type="text" class="form-control input-address-delivery" v-model="kodePos" placeholder="Ex: 16320" >
                                        </div>
                                        <div class="col-md">
                                            <!-- <div style="visibility:hidden;">d</div>
                                            <button type="button" class="button-blue" @click="doCheckLocation">Check Location</button> -->
                                        </div>
                                    </div>
                                    <br>

                                    <hr>
                                    
                                    <!-- <div class="row">
                                        <div class="col-md">
                                            <div>Latitude</div>
                                            <input type="text" class="form-control input-address-delivery" v-model="latitude" placeholder="Ex: -6.4610789" >
                                        </div>
                                        <div class="col-md">
                                            <div>Longitude</div>
                                            <input type="text" class="form-control input-address-delivery" v-model="longitude" placeholder="Ex: 106.8043952" >
                                        </div>
                                    </div> -->
                                    <!-- <div class="click-link" @click="$bvModal.show('gmap-modal')">
                                        Click here to check location on google maps
                                    </div> -->
                                    <br>

                                    <!-- <div class="row">
                                        <div class="col-md">
                                            <div>Pickup Location <span style="font-size:12px">(Optional)</span></div>
                                            <gmap-autocomplete id="refDeliveryPoint" class="form-control input-address-delivery" :value="lokasiPickup" @place_changed="getPickupPoint($event)" placeholder="ex: Jl. Nusantara. Komp. Ruko Lokasari No. 7A Hankam"  />
                                        </div>                                        
                                    </div>                                    
                                    <br> -->

                                    <div class="row">
                                        <div class="col-md">
                                            <span>{{$t('Temperature')}}</span>
                                            <input type="number" class="form-control input-item-delivery" v-model="minTemperature" min="-22" max="10" :placeholder="$t('type here')" @change="checkTemperature(temperaturMin, 'min')">
                                        </div>

                                        <!-- <div class="col-md">
                                            <span>Min Temperature</span>
                                            <input type="number" class="form-control input-item-delivery" v-model="maxTemperature" min="-22" max="10" placeholder="type here" @change="checkTemperature(temperaturMin, 'min')">
                                        </div> -->

                                        <div class="col-md-6">
                                            <span>{{$t('PIC Name')}}</span>
                                            <input type="text" class="form-control input-address-delivery" v-model="picname" :placeholder="$t('type here')">
                                        </div>
                                    </div>
                                    <br>
                                    <!-- <div class="row">
                                        <div class="col-md-6">
                                            <span>PIC Name</span>
                                            <input type="text" class="form-control input-address-delivery" v-model="picname" placeholder="type here">
                                        </div>
                                    </div> -->

                                    

                                    <div class="image-text mt-3">
                                        <button type="button" class="button-form-blue" @click="doUpdateData">{{$t('Save Changes')}}</button>
                                    </div>
                                    
                                </div>
                            </div>
                            <br><br><br>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-md-none">
            <BotNavbar :page="'setting'"/>
        </div>
        <!-- <Modal :dataGmap="'https://maps.google.com/?q='+this.latitude+','+this.longitude+'&z=19&output=embed'"/>  -->
        <!-- <b-modal ref="gmap-modal"  id="gmap-modal" size="lg" centered hide-footer hide-header>
            <div class="">
                <div id="gmapIframe">
                    <iframe id="myFrame" :src="this.dataGmap" allow="geolocation" height="490px" width="100%" title="Iframe Example"></iframe>
                </div>
                <div class="row">
                    <div class="col-md">
                        <div>Pickup Location <span style="font-size:12px">(Optional)</span></div>
                        <gmap-autocomplete id="refDeliveryPoint" class="form-control input-address-delivery" :value="lokasiPickup" @place_changed="getPickupPoint($event)" placeholder="ex: Jl. Nusantara. Komp. Ruko Lokasari No. 7A Hankam"  />
                        
                        <div class="row" style="margin-top:10px">
                            <div class="col">
                                <button class="button-blue-8 btn-lg left-in-phone" style="width:100%;margin-left:0px" @click="$bvModal.hide('gmap-modal')"> Pick Location</button>
                            </div>
                        </div>
                    </div>                                        
                </div>                                    
                <br>
            </div>
        </b-modal> -->

        <b-modal ref="gmap-modal"  id="gmap-modal" size="lg" centered hide-footer hide-header>
            <div class="">
                <div id="gmapIframe">
                    <iframe id="myFrame" :src="this.dataGmap" :set="this.dataGmap = (this.latitude != '' && this.longitude != '')?'https://maps.google.com/?q='+this.latitude+','+this.longitude+'&z=19&output=embed':'https://maps.google.com/?q=-6.1496277,106.8306063&z=19&output=embed'" allow="geolocation" height="490px" width="100%" title="Iframe Example"></iframe>
                </div>
                <div class="row">
                    <div class="col-md">
                        <div>{{$t('Input location (Optional)')}} <span style="font-size:12px">({{$t('Optional')}})</span></div>
                        <!-- <gmap-autocomplete id="refDeliveryPoint" class="form-control input-address-delivery" @place_changed="getDataPickLocation($event, index)" placeholder="ex: Jl. Nusantara. Komp. Ruko Lokasari No. 7A Hankam"  /> -->
                        <vue-bootstrap-typeahead 
                            v-model="addressSearch"
                            :data="addresses"
                            :serializer="s => s.address.label"
                            @hit="selectAutoSuggestHereMap($event)"
                            @input="autoSuggestHereMap(addressSearch)"
                            inputClass="form-control input-address-delivery"
                            />
                        <div class="row" style="margin-top:10px">
                            <div class="col">
                                <button class="button-blue-8 btn-lg left-in-phone" style="width:100%;margin-left:0px" @click="doPickLocation()"> {{$t('Pick Location')}}</button>
                            </div>
                        </div>
                    </div>                                        
                </div>                                    
                <br>
            </div>
        </b-modal>
    </div>
</template>

<script>
import Sidebar from '@/components/Sidebar.vue'
import Navbar from '@/components/Navbar_Login.vue'
import BotNavbar from '@/components/BottomNavBar.vue'
import axios from "axios";
import {HTTP} from '@/constants'
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'
//import Modal from '@/components/Modal.vue'

export default {
    name:"UserSettings",
    components: {
        Sidebar,
        Navbar,
        BotNavbar,
        VueBootstrapTypeahead
        //Modal
    },
    data(){
        return{
            minTemperature:"",
            maxTemperature:this.minTemperature,
            refId:"",
            tokenUser:"",
            alamat:"",
            lokasiPickup:"",
            apartemen:"",
            kota:"Select City",
            latitude:"",
            longitude:"",
            latlong:"",
            provinsi:"Select State",
            labelAlamat:"",
            kodePos:"",
            dataCitys:[],
            dataGmap:"",
            dataLocation:"",
            picname:"",
            dataProvinces:[],
            query:"",
            addresses: [],
            addressSearch: '',
            selectedAddress: null
        }
    },
    mounted(){
        this.dataGmap = 'https://maps.google.com/?q='+this.latitude+','+this.longitude+'&z=19&output=embed';
    },
    created(){
        if(localStorage.getItem('token') == null){
            this.$toast.error(this.$t('Please log in to your account first!'), {
                type:'error',
                position:'top-right',
                duration:3000,
                dismissible:true
            });
            this.$router.push("/");
        }else{
            this.refId = localStorage.getItem('refId');
            this.tokenUser = localStorage.getItem('token');
            this.getDataCustomer();
        }

        this.getProvince();
        //this.autoSuggestHereMap("jakarta");
    },
    watch:{
        
    },
    methods:{
        async autoSuggestHereMap(query){
            axios.get("https://autosuggest.search.hereapi.com/v1/autosuggest?at=-2.4153279,108.8516932&limit=100&lang=id&q="+query+"&apiKey=SNj0BOytgEjBLygUZvdZr-nVdfUlq6eSELESmm72HLI")
            .then((response)=>{
                console.log(response.data);
                this.addresses = response.data.items;
            }).catch((err)=>{
                console.log(err);
            });
        },
        getDataCustomer(){
            axios.post(HTTP+"api/v1/c-info", 
                {
                    reference_id:this.refId,
                },{
                    headers:{
                        "Authorization": "Bearer "+ localStorage.getItem("token")
                    }
                })
                .then((response)=>{
                    if(response.data.status == "success"){
                        console.log(response.data);
                        // console.log(response.data.data.user_address[0]);
                        // console.log(response.data.data.user_address[0].city);
                        this.minTemperature = response.data.data.temperatur_min;
                        this.maxTemperature = response.data.data.temperatur_max;
                        this.alamat = response.data.data.user_address[0].address;
                        this.apartemen = response.data.data.user_address[0].apartment;
                        this.kota = response.data.data.user_address[0].city;
                        this.provinsi = response.data.data.user_address[0].province;
                        this.kodePos = response.data.data.user_address[0].postal_code;
                        this.latitude = response.data.data.user_address[0].latitude;
                        this.longitude = response.data.data.user_address[0].longitude;
                        this.lokasiPickup = response.data.data.user_address[0].address_google_location;
                        this.picname = response.data.data.user_address[0].pic_name;

                        this.dataGmap = 'https://maps.google.com/?q='+this.latitude+','+this.longitude+'&z=19&output=embed';

                        if(this.provinsi.length > 0){
                            this.getCity(this.provinsi);
                        }
                        
                    }else{
                        console.log('gagal anjir');
                    }
                    
                }).catch((err)=>{
                    console.log(err);
                });
        },
        doUpdateData(){
            this.$toast.warning("Wait, on process!", {
                type:'warning',
                position:'top-right',
                duration:3000,
                dismissible:true
            });

            axios.post(HTTP+"api/v1/update-c-info", {
                temperatur_min:this.minTemperature,
                temperatur_max:this.minTemperature,
                address:this.alamat+", "+this.apartemen+", "+this.kota+", "+this.provinsi+", "+this.kodePos,
                apartment:this.apartemen,
                postal_code:this.kodePos,
                province:this.provinsi,
                city:this.kota,
                latitude:this.latitude,
                longitude:this.longitude,
                pic_name: this.picname,
            },{
                headers:{
                    Authorization: "Bearer "+this.tokenUser
                }
            }).then((response)=>{
            if(response.data.status == "success"){
                this.$toast.success(response.data.message, {
                    type:'success',
                    position:'top-right',
                    duration:3000,
                    dismissible:true
                });

            }else{
                this.$toast.error(response.data.message, {
                    type:'error',
                    position:'top-right',
                    duration:3000,
                    dismissible:true
                });
            }
            }).catch((err)=>{
                console.log(err);
            });
        },
        doCheckLocation(){
            this.$toast.warning("Wait, on process!", {
                type:'warning',
                position:'top-right',
                duration:3000,
                dismissible:true
            });

            axios.post(HTTP+"api/v1/geocoding", {
                apartment:this.apartemen,
                streetName:this.alamat,
                landmark:"",
                city:this.kota,
                state:this.provinsi,
                postal_code:this.kodePos,
            }).then((response)=>{
            if(response.data.status == "success"){
                this.$toast.success(response.data.message, {
                    type:'success',
                    position:'top-right',
                    duration:3000,
                    dismissible:true
                });

                this.latitude = response.data.data.latitude;
                this.longitude = response.data.data.longitude;

            }else{
                this.$toast.error(response.data.message, {
                    type:'error',
                    position:'top-right',
                    duration:3000,
                    dismissible:true
                });
            }
            }).catch((err)=>{
                console.log(err);
            });
        },
        getProvince(){
            axios.post(HTTP+"api/v1/province-list").then((response)=>{
                if(response.data.status == "success"){
                    this.dataProvinces = response.data.data;
                }else{
                    this.$toast.error(response.data.message, {
                            type:'error',
                            position:'top-right',
                            duration:3000,
                            dismissible:true
                        });
                }
            }).catch((e)=>{
                console.log(e);
            });
        },
        getCity(data){

            axios.post(HTTP+"api/v1/city-list", {
                province:data
            }).then((response)=>{
                if(response.data.status == "success"){
                    this.dataCitys = response.data.data;
                }else{
                    this.$toast.error(response.data.message, {
                            type:'error',
                            position:'top-right',
                            duration:3000,
                            dismissible:true
                        });
                }
            }).catch((e)=>{
                console.log(e);
            });
        },
        getPickupPoint(point){
            this.pickupPoint = point;
            console.log(point.formatted_address);
            console.log(point.name);
            console.log(point);
            this.lokasiPickup = point.formatted_address;
            this.latlong = point.geometry.location.lat()+","+point.geometry.location.lng();
            this.longitude = point.geometry.location.lng();
            this.latitude = point.geometry.location.lat();
            console.log("Masuukk guyss");
            this.dataGmap = 'https://maps.google.com/?q='+this.latitude+','+this.longitude+'&z=19&output=embed';
            //this.deliveryRoutes[id].provinsi = point.address_components[point.address_components.length - 3].long_name

            let postcode = "";

            for (const component of point.address_components) {
                const componentType = component.types[0];

                switch (componentType) {
                case "postal_code": 
                    this.kodePos = `${component.long_name}${postcode}`;
                    break;
                
                case "postal_code_suffix": 
                    this.kodePos = `${postcode}-${component.long_name}`;
                    break;
                
                default:
                    this.kodePos = "";
                    break;
                }
            }
        },
        getDataPickLocation(point, id){
            console.log(point.formatted_address);
            console.log(point.name);
            console.log(point);
            console.log(id);
            console.log('https://maps.google.com/?q='+point.geometry.location.lat()+','+point.geometry.location.lng()+'&z=19&output=embed');
            this.dataLocation = point;
            this.dataGmap = 'https://maps.google.com/?q='+point.geometry.location.lat()+','+point.geometry.location.lng()+'&z=19&output=embed';
            this.longitude = point.geometry.location.lng();
            this.latitude = point.geometry.location.lat();
        },
        doPickLocation(){
            // var point = this.dataLocation;
            var data = this.dataLocation;

            // this.alamat = point.formatted_address;
            // this.latlong = point.geometry.location.lat()+","+point.geometry.location.lng();
            // this.longitude = point.geometry.location.lng();
            // this.latitude = point.geometry.location.lat();
            
            // let postcode = "";

            // for (const component of point.address_components) {
            //     const componentType = component.types[0];

            //     switch (componentType) {
            //     case "postal_code": 
            //         this.kodePos = `${component.long_name}${postcode}`;
            //         break;
                
            //     case "postal_code_suffix": 
            //         this.kodePos = `${postcode}-${component.long_name}`;
            //         break;
                
            //     default:
            //         this.kodePos = "";
            //         break;
            //     }
            // }

            // HERE MAP
            this.alamat = data.address.label;
            this.lokasiPickup = data.address.label;
            this.latlong = data.position.lat+","+data.position.lng;
            this.longitude = data.position.lng;
            this.latitude = data.position.lat;
            this.selectedAddress = data;
            console.log("Masuukk guyss");
            // this.dataGmap = 'https://maps.google.com/?q='+this.latitude+','+this.longitude+'&z=19&output=embed';

            this.$bvModal.hide('gmap-modal');
            //this.$refs['gmap-modal'+id].hide();

        },
        selectAutoSuggestHereMap(data){
            this.pickupPoint = data;
            this.dataLocation = data;
            this.lokasiPickup = data.address.label;
            this.latlong = data.position.lat+","+data.position.lng;
            this.longitude = data.position.lng;
            this.latitude = data.position.lat;
            this.selectedAddress = data;
            console.log("Masuukk guyss");
            this.dataGmap = 'https://maps.google.com/?q='+this.latitude+','+this.longitude+'&z=19&output=embed';
            //this.deliveryRoutes[id].provinsi = point.address_components[point.address_components.length - 3].long_name

        },
        checkTemperature(data, ket){

            if(data >= -22 && data <= 10 ){
                if(ket == "max"){
                    if(parseFloat(this.temperaturMin) > parseFloat(this.temperaturMax)){
                        this.temperaturMax = 10;
                    }
                }
            }else{
                if(ket == "max"){
                    this.temperaturMax = 10;
                }else{
                    this.temperaturMin = -22;
                }
            }
        },
    }
}
</script>

<style>
    .userSettings{
        border-top: 2px solid rgb(240, 240, 240);
    }
    div.pac-container {
        z-index: 99999999999 !important;
    }
</style>