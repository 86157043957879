<template>
    <div>
        <Navbar/>
        <div class="orderDetail">
            <div class="container">
                
                <div class="row">
                    <div class="col-md-3 d-none d-md-block">
                        <Sidebar/>
                    </div>
                    <div class="col ml-3">
                        <div class="">
                        <div style="margin-top:50px">
                            <h4>Order Detail</h4>
                        </div>

                        <div style="margin-top:30px">
                            Order ID
                            <div class="ft-10">
                                {{dataOrder.order_no}}
                            </div>
                        </div>

                        <div style="margin-top:30px">
                            Delivery to
                            <div class="ft-10">
                                {{dataOrder.deliver_details.deliverAccountName}}
                            </div>
                        </div>

                        <div style="margin-top:30px">
                            Service Type
                            <div class="ft-10">
                                {{orderDetail.service_type}}
                            </div>
                        </div>

                        <div style="margin-top:30px">
                            <span>{{dataOrder.shipment_order_date}}</span><br>
                            <!-- <span>Time: 10:50 WIB</span> -->
                        </div>

                        <hr/>
                        
                        <div style="margin-left:30px">
                            <div class="row">             
                                <div class="col">
                                    <div>
                                        <b>Pickup:</b>
                                    </div>
                                    <div>
                                        <b>{{dataOrder.pickup_details.pickupStreetName}}</b>
                                    </div>
                                    <div>
                                        {{dataOrder.pickup_details.pickupCity}}
                                    </div>
                                </div>
                                <div class="col"></div>
                                <div class="col">
                                    <div >
                                        <b>Delivery to:</b>
                                    </div>
                                    <div >
                                        <b>{{dataOrder.deliver_details.deliverStreetName}}</b>
                                    </div>
                                    <div >
                                        {{dataOrder.deliver_details.deliverCity}}
                                    </div>
                                </div>                
                            </div>        
                        </div>

                        <!-- <ul class="progressbar" style="margin-top:30px" v-for="dataTrip in dataOrder.tracking" :key="dataTrip.id">
                            <li class="checklist"> 
                                <div class="content-order-detail">
                                    <div class="row">
                                        <div class="col-1 item-image-order-detail">
                                            <img src="/icon/ic_order_pickup.png" >
                                        </div>
                                        <div class="col">
                                            <b>Order Picked</b><br/>
                                            <span>From Freezy Fresh Depok</span>
                                        </div>
                                        <div class="col">
                                            <div class="text-right"><b>12:00</b></div>
                                        </div>
                                    </div>
                                </div> 
                            </li> -->
                            <!-- <li class="checklist"> 
                                <div class="content-order-detail">
                                    <div class="row">
                                        <div class="col-1 item-image-order-detail" >
                                            <img src="/icon/ic_delivery.png" >
                                        </div>
                                        <div class="col">
                                            <b>ORDER {{dataTrip.response_data.orderLeg}}</b><br/>
                                            <span>From {{dataTrip.response_data.branchName}}</span>
                                        </div>
                                        <div class="col">
                                            <div class="text-right"><b>{{ convertDate(dataTrip.created_at)}}</b></div>
                                        </div>
                                    </div>
                                </div> 
                            </li> -->
                            <!-- <li class="checklist"> 
                                <div class="content-order-detail">
                                    <div class="row">
                                        <div class="col-1 item-image-order-detail" >
                                            <img src="/icon/ic_delivery_location.png" >
                                        </div>
                                        <div class="col">
                                            <b>Order Picked</b><br/>
                                            <span>From Freezy Fresh Depok</span>
                                        </div>
                                        <div class="col">
                                            <div class="text-right"><b>12:00</b></div>
                                        </div>
                                    </div>
                                </div> 
                            </li>              -->
                    <!-- </ul> -->
                        <br><br>
                        <!-- <div class="card-rating">
                            <div class="row">
                                <div class="col-2">
                                    <img src="/icon/ic_rating.png" alt="">
                                </div>
                                <div class="col">
                                    <b>Don't forget to rate us</b><br/>
                                    <span>How do you like our services?</span>
                                </div>
                            </div>                
                        </div>                
                        <br><br> -->
                        <iframe id="myFrame" scrolling="no" :src="clientCode" height="650px" width="100%" title="Iframe Example"></iframe>
                        <br><br>
                    </div>
                    </div>
                </div>
            </div>
            <br><br>
        </div>
        <div class="d-md-none">
            <BotNavbar :page="'history'"/>
        </div>
    </div>
</template>

<script>
import Sidebar from '@/components/Sidebar.vue'
import Navbar from '@/components/Navbar_Login.vue'
import BotNavbar from '@/components/BottomNavBar.vue'

export default {
    name:"OrderItemDetail",
    components:{
        Sidebar,
        Navbar,
        BotNavbar
    },
    data(){
        return{
            dataOrder:"",
            clientCode:"",   
            orderDetail:"",
            statusorder:"",      
        }
    },
    created(){
        if(localStorage.getItem('token') == null){
            this.$toast.error(this.$t('Please log in to your account first!'), {
                type:'error',
                position:'top-right',
                duration:3000,
                dismissible:true
            });
            this.$router.push("/");
        }else{
            var data = JSON.parse(localStorage.getItem('dataItems'));
            var stats = localStorage.getItem("statusOrder");
            this.statusorder = stats;
            this.dataOrder = data; 
            this.clientCode = this.dataOrder.client_code;
            this.clientCode = "https://api.loginextsolutions.com/track/#/order?ordno="+this.clientCode+"&aid=622d1fe4-43fa-4a30-b0c2-d40150ca2ca1";
            console.log(data);
            var order = JSON.parse(localStorage.getItem('dataOrder'));
            this.orderDetail = order;
        }
  },
  mounted(){
      
  },
  methods:{
      convertDate(date){
            var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear(),
            hours = d.getHours(),
            minute = d.getMinutes();

            if (month.length < 2) 
                month = '0' + month;
            if (day.length < 2) 
                day = '0' + day;

            return [year, month, day].join('-') + " "+ [hours, minute].join(":");
      }
  }
}


</script>

<style>
    .orderDetail{
        border-top: 2px solid lightgrey;
    }

    .ft-10{
        font-size: 20px;
    }

    .pt-8{
        margin-top: 30px;
    }

    .pt-10{
        margin-top: 50px;
    }

    .pl-10{
        margin-left: 50px;
    }    
</style>