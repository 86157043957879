<template>
    <div>
        <div v-if="isLogin">
            <NavbarLogin/>
        </div>

        <div v-else>
            <Navbar/>
        </div>

        <div class="termPage">
            <div class="container mt-3">
                <div style="width:100%;">
                    <div v-if="isLoading">
                        <center>
                            <br>
                            <img src="/icon/loading.gif" alt="">
                            <h3>Loading...</h3>
                            <br><br><br><br>
                        </center>
                    </div>
                    <div v-else>
                        <div v-html="dataTerm"></div>
                    </div>
                </div>
            </div>
        </div>
        
        <BottomBar/>
    </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import NavbarLogin from '@/components/Navbar_Login.vue'
import BottomBar from '@/components/BottomBar.vue'
import axios from "axios";
import {HTTP} from '@/constants'

export default {
    name:"TermConditionPage",
    components: {
        Navbar,
        NavbarLogin,
        BottomBar,
    },
    data() {
      return {
          isLogin:false,
          tokenUser:"",
          dataTerm:"",
          isLoading:false,
      }
    },
    created(){
        if(localStorage.getItem('token') != null){
            this.isLogin = true;
            this.tokenUser = localStorage.getItem('token');
        }else{
            this.isLogin = false;
        }

        this.getDataFaq();
    },
    methods:{
        getDataFaq(){
            this.isLoading = true;
            var lang = localStorage.getItem('lang');
            axios.get(HTTP+"api/v1/term-and-condition/"+lang).then((response)=>{
                this.isLoading = false;
                this.dataTerm = response.data.term;
                console.log(response.data.faq);
            }).catch((err)=>{
                this.isLoading = false;
                console.log(err);
            });
        }
    }
}
</script>

<style>
    .termPage{
        border-top: 2px solid lightgrey;
        width: 100%;
    }
</style>