<template>
  <div>
    <b-navbar toggleable="lg" type="light">
      <div class="container">
        <b-navbar-brand @click="goToHome">
            <img src="../assets/images/logo.png" width="157" />
        </b-navbar-brand>

        <b-navbar-toggle target="nav-collapse "></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav>
          </b-navbar-nav>
        
          <!-- Center aligned nav items -->
          <b-navbar-nav class="ml-auto">
            <b-nav-item @click="goToPrivacyPolicy">
                <div class="menu-nav">
                  {{ $t('privacyPolice') }} 
                </div>
            </b-nav-item>            
            <b-nav-item @click="goToPricing">
                <div class="menu-nav">
                    {{ $t('pricing') }} 
                </div>
            </b-nav-item>
            <b-nav-item @click="goToFaq">
                <div class="menu-nav">
                    {{ $t('faq') }} 
                </div>
            </b-nav-item>
            <b-nav-item @click="$bvModal.show('register-modal')">
                <div class="menu-nav" >
                    {{ $t('Register') }} 
                </div>
            </b-nav-item>
          </b-navbar-nav>

          <!-- Right aligned nav items -->
          <b-navbar-nav class="ml-auto">
              <b-nav-item>
                <button type="button" class="menu-nav-button-start" style="border-radius: 30px;" @click="goToOrder">
                  {{ $t('StartDelivery') }}
                </button>
                  
              </b-nav-item>
              <b-nav-item>
                <!-- <div class="menu-nav-button-loginregister">Login / Register</div> -->
                <!-- Button trigger modal -->
                <div class="menu-nav-button-loginregister"  style="padding-left:50px;padding-right:50px;" @click="$bvModal.show('login_modal')">
                  {{ $t('Login') }}
                </div>

                <!-- Modal -->
              </b-nav-item>

              <b-nav-item>
              <select class="form-control selectbox-nav d-none d-md-block" v-model="lang" @change="changeLang(lang)">
                  <option value="en">English</option>
                  <option value="id">Indonesia</option>
              </select>
            </b-nav-item>
          </b-navbar-nav>
        </b-collapse>
      </div>
    </b-navbar>
    <Modal/> 
  </div>
</template>

<script>
// @ is an alias to /src
import Modal from '@/components/Modal.vue'

export default {
  name: "Navbar",
  components: {
    Modal
  },
  data(){
    return{
      lang:(localStorage.getItem("lang") == null )? "en" : (localStorage.getItem("lang") == "en")?"en":"id",
    }
  },
  methods:{
    goToOrder(){
      this.$router.push("OrderDelivery").catch(()=>{});
    },
    goToHome(){
      this.$router.push("/").catch(()=>{});
    },
    goToPrivacyPolicy(){
      this.$router.push("PrivacyPolicy").catch(()=>{});
    },
    goToPricing(){
      this.$router.push("Pricing").catch(()=>{});
    },
    goToFaq(){
      this.$router.push("FAQ").catch(()=>{});
    },
    changeLang(data){
      console.log(data)
      localStorage.setItem("lang", data);

      // this.$router.push("/");
      // this.$router.reload();
      this.$router.go(0);
    }
  }
};
</script>