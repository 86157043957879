<template>
    <div>
        <div class="bg-bottom margin-top-footbar">
          <div class="container">
            <div class="row">
              <div class="col-md-4" style="margin-top:30px">
                <div>
                  <center>
                    <img src="/images/logo.png" alt="">                  
                  </center>
                </div>
                <br>
                <div>
                  <p> <center>PT Superkul Amerta Indonesia<br> Jalan Kartini Raya no 64, Jakarta Pusat<br>DKI Jakarta-Indonesia</center></p>
                </div>
                <div>
                  <p><center>Admin : <b>0821-30000-298</b></center></p>
                </div>
                <div>
                  <div class="row">
                    <div class="col-md-3"></div>
                    <div class="col-md align-self-center" >
                      <div class="row">
                        <div style="margin-left:20px">
                          <img src="/icon/ic_twetter.png" alt="">
                        </div>
                        <div style="margin-left:5px">
                          <img src="/icon/ic_youtube.png" alt="">
                        </div>
                        <div style="margin-left:5px">
                          <img src="/icon/ic_instagram.png" alt="">
                        </div>
                        <div style="margin-left:5px">
                          <img src="/icon/ic_facebook.png" alt="">
                        </div>
                      </div>                      
                    </div>
                  </div>                 
                </div>
                <br>
                <div>
                  <p> <center>{{ $t('Copyright2021Superkul') }} <br> {{ $t('AllRightsReserved') }}</center></p>
                </div>
              </div>
              <div class="col-md-2"></div>
              <div class="col-md-6" style="margin-top:30px">
                <div class="row">
                  <div class="col-auto">
                    <br>
                    <div class="font-footbar-title">
                      <b>{{ $t('SiteMap') }}</b>
                    </div>
                    <div v-if="isLogin == false">
                      <div class="font-footbar cursor-pointer"  @click="$bvModal.show('login_modal')">
                        {{ $t('Login') }}
                      </div>
                      <div class="font-footbar cursor-pointer" @click="$bvModal.show('register-modal')">
                        {{ $t('Register') }}
                      </div>
                    </div>
                    <div class="font-footbar cursor-pointer" @click="goToHome">
                      {{ $t('Home') }}
                    </div>
                    <div class="font-footbar cursor-pointer" @click="goToPricing">
                      {{ $t('pricing') }}
                    </div>
                    <div class="font-footbar cursor-pointer" @click="goToFaq">
                      {{ $t('faq') }}
                    </div>
                    <div class="font-footbar cursor-pointer" @click="goToPrivacy">
                      {{ $t('privacyPolice') }}
                    </div>
                    <div class="font-footbar cursor-pointer" @click="goToTerm">
                      {{ $t('Terms&Conditions') }}
                    </div>
                  </div>

                  <div class="col-8" style="margin-top:0px"> 
                    <div class="font-footbar-title" style="margin-bottom:5px">
                      {{ $t('AskUsAnything') }}
                    </div>      
                    <div class="form-group">
                        <input type="text" class="form-control form-footbar" :placeholder="$t('YourName')" v-model="name">
                    </div>
                    <div class="form-group">
                        <input type="text" class="form-control form-footbar" :placeholder="$t('EmailAddress')" v-model="email">
                    </div>
                    <div class="form-group">
                        <textarea type="text" class="form-control form-footbar" :placeholder="$t('Message')" rows="4" v-model="message"/>
                    </div>
                    <div class="form-group mt-3">
                        <button class="button-footbar" style="border-radius: 30px; width: 100%" @click="sendEmail">{{$t("Submit")}}</button>
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <Modal/>  -->
    </div>
</template>

<script>
//import Modal from '@/components/Modal.vue'
import axios from "axios";
import {HTTP} from '@/constants'

export default {
    name:"BottomBar",
    components: {
     // Modal
    },
    data(){
        return{
            name:"",
            email:"",
            message:"",
            isLogin:false,
        }
    },
    created(){
        if(localStorage.getItem('name') != null){
            this.isLogin = true;
        }else{
            this.isLogin = false;
        }
    },
    methods:{
      goToHome(){
        this.$router.push("/");
      },
      goToPricing(){
        this.$router.push("Pricing");
      },
      goToFaq(){
        this.$router.push("FAQ");
      },
      goToPrivacy(){
        this.$router.push("PrivacyPolicy");
      },
      goToTerm(){
        this.$router.push("TermCondition");
      },
      goToLogin(){
        this.$refs['login-modal'].show();
      },
      sendEmail(){
          if(this.name.length < 0){
            this.$toast.error(this.$t('Name is required'), {
                  type:'error',
                  position:'top-right',
                  duration:3000,
                  dismissible:true
              });
              return;
          }

          if(this.email.length < 0){
            this.$toast.error(this.$t('Email is required!'), {
                  type:'error',
                  position:'top-right',
                  duration:3000,
                  dismissible:true
              });
              return;
          }

          if(this.message.length < 0){
            this.$toast.error(this.$t('Message is required!'), {
                  type:'error',
                  position:'top-right',
                  duration:3000,
                  dismissible:true
              });
              return;
          }
          
          this.$toast.warning(this.$t('Wait, on process!'), {
              type:'warning',
              position:'top-right',
              duration:3000,
              dismissible:true
          });

          axios.post(HTTP+"api/v1/contact-us", {
              name:this.name,
              email:this.email,
              message:this.message,
          }).then((response)=>{
          if(response.data.status == "success"){
              this.$toast.success(response.data.message, {
                  type:'success',
                  position:'top-right',
                  duration:3000,
                  dismissible:true
              });

          }else{
              this.$toast.error(response.data.message, {
                  type:'error',
                  position:'top-right',
                  duration:3000,
                  dismissible:true
              });
          }
          }).catch((err)=>{
              console.log(err);
          });
      }
    }
}
</script>

<style>

</style>