<template>
    <div>
        <Navbar/>
        <div class="orderHistory2">
            <div class="container">
                <div class="row">
                    <div class="col-md-3 d-none d-md-block">
                        <Sidebar/>
                    </div>
                    <div class="col ml-3">
                        <br><br>
                        <h4>{{$t('Order History')}}</h4>
                        <br>
                        <div v-if="isLoading">
                            <center>
                                <br>
                                <img src="/icon/loading.gif" alt="">
                                <h3>Loading...</h3>
                            </center>                    
                        </div>
                        <div v-else>
                            <div v-if="dataorders.length < 1">
                                <br><br><br><br><br>
                                <center>
                                    <h2>{{$t('No Data Order')}} ;(</h2>
                                </center>
                            </div>
                            <div v-else>
                                <div v-for="dataOrder in dataorders" :key="dataOrder.id">
                                    <ItemOrderHistory :dataOrder="dataOrder" :dataKey="dataKey"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br><br><br>
        </div>
        <div class="d-md-none">
            <BotNavbar :page="'history'"/>
        </div>        
    </div>
</template>

<script>
import ItemOrderHistory from '@/components/ItemOrderHistory.vue'
import Sidebar from '@/components/Sidebar.vue'
import Navbar from '@/components/Navbar_Login.vue'
import BotNavbar from '@/components/BottomNavBar.vue'
import axios from 'axios'
import {HTTP} from '@/constants'

export default {
    name:"OrderHistory",
    components:{
        ItemOrderHistory,
        Sidebar,
        Navbar,
        BotNavbar
    },
    data(){
        return{
            refId:"",
            tokenUser:"",
            dataKey:"OrderHistory",
            isLoading:true,
            dataorders:[],          
        }
    },
    created(){
        if(localStorage.getItem('token') == null){
            this.$toast.error(this.$t('Please log in to your account first!'), {
                type:'error',
                position:'top-right',
                duration:3000,
                dismissible:true
            });
            this.$router.push("/");
        }else{
            this.refId = localStorage.getItem('refId');
            this.tokenUser = localStorage.getItem('token');
        }
    },
    mounted(){
        this.isLoading = true;
        axios.post(HTTP+"api/v1/get-order",{},{
            headers:{
                "Authorization":"Bearer "+this.tokenUser
            }
        }).then((response)=>{
            this.isLoading = false;
            console.log(response.data.data);
            this.dataorders = response.data.data;
        }).catch((err)=>{
            this.isLoading = false;
            console.log(err);
        });
    },
    methods:{
        
    }
    
}
</script>

<style>
    .orderHistory2{
        border-top: 2px solid rgb(240, 240, 240);
    }
</style>