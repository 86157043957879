<template>
    <div>
        <Navbar/>

        <div class="exporthistory">
            <div class="container">
                <div class="row">
                    <div class="col-md-3 d-none d-md-block">
                        <Sidebar/>
                    </div>

                    <div class="col ml-3">
                        <div class="mt-5">
                            <h2>{{$t('Export History')}}</h2> 
                        </div>

                        <div class="mt-4">
                            <div class="row">
                                <div class="col-md-4">
                                    <div style="margin-bottom:10px">{{$t('Please Select Start Date')}}</div>
                                    <b-form-datepicker id="datepickerStart" v-model="startDate" class="mb-2 input-datetime-picker"></b-form-datepicker>
                                    <!-- <p>Value: '{{ startDate }}'</p> -->
                                </div>
                                <div class="col-md-4">
                                    <div style="margin-bottom:10px">{{$t('Please Select End Date')}}</div>
                                    <b-form-datepicker id="datepickerEnd" v-model="endDate" class="mb-2 input-datetime-picker"></b-form-datepicker>
                                    <!-- <p>Value: '{{ endDate }}'</p> -->
                                </div>
                                <div class="col-md-4">
                                    <div style="margin-bottom:0px;color:white;">button</div>
                                    <button type="button" class="button-form-blue" @click="doExport">{{$t('Export')}}</button>
                                    <!-- <p>Value: '{{ dateForm }}'</p> -->
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>

        <div class="d-md-none">
            <BotNavbar :page="'exporthistory'"/>
        </div>
    </div>
</template>

<script>
import Sidebar from '@/components/Sidebar.vue'
import Navbar from '@/components/Navbar_Login.vue'
import BotNavbar from '@/components/BottomNavBar.vue'
import axios from "axios"
import {HTTP} from '@/constants'

export default {
    name:"ExportHistoryPage",
    components: {
        Sidebar,
        Navbar,
        BotNavbar,
    },
    data() {
        return {
            refId:"",
            tokenUser:"",
            startDate:"",
            endDate:"",
        }
    },
    created(){
        if(localStorage.getItem('token') == null){
            this.$toast.error("Please log in to your account first!", {
                type:'error',
                position:'top-right',
                duration:3000,
                dismissible:true
            });
            this.$router.push("/");
        }else{
            this.refId = localStorage.getItem('refId');
            this.tokenUser = localStorage.getItem('token');
        }
    },
    methods:{
        doExport(){
            axios.post(HTTP+"api/v1/get-order-history", {
                sdate:this.startDate,
                edate:this.endDate,
            },{
                responseType:'blob',
                headers:{
                    "Authorization": "Bearer "+ localStorage.getItem("token")
                }
            }).then((response)=>{
                console.log(response.status);
                var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                var fileLink = document.createElement('a');

                fileLink.href = fileURL;
                // fileLink.setAttribute('download', 'file.xlsx');
                fileLink.setAttribute('download', 'history_export.csv');
                document.body.appendChild(fileLink);

                fileLink.click();
            }).catch((err)=>{
                console.log(err);
            });
        }
    }
}
</script>

<style>
    .exporthistory{
        border-top: 2px solid rgb(240, 240, 240);
    }
</style>