<template>
    <div class="sidebar">
        <div class="menu-items">
            <router-link to="/UserInfo" active-class="active" tag="button" class="side-btn active">
                <div class="link-container">
                    <font-awesome-icon icon="user"/> {{$t('User Info')}}
                </div>
            </router-link>
            <router-link to="/OrderHistory" active-class="active" tag="button" class="side-btn active">
                <div class="link-container">
                    <font-awesome-icon icon="history"/> {{$t('Order History')}}
                </div>
            </router-link>
            <router-link to="/exporthistory" active-class="active" tag="button" class="side-btn active">
                <div class="link-container">
                    <!-- <font-awesome-icon icon="cog"/> Settings -->
                    <b-icon icon="file-earmark-arrow-down-fill" aria-hidden="true"></b-icon> {{$t('Export History')}}
                </div>
            </router-link>
            <router-link to="/UserSettings" active-class="active" tag="button" class="side-btn active">
                <div class="link-container">
                    <!-- <font-awesome-icon icon="cog"/> Settings -->
                    <b-icon icon="gear-fill" aria-hidden="true"></b-icon> {{$t('Settings')}}
                </div>
            </router-link>
            <br><br><br><br><br><br><br>
            <!---<router-link to="/" active-class="active" tag="button" class="side-btn active">
                <div class="link-container" @click="doLogOut">
                    <font-awesome-icon icon="history"/> Logout
                </div>
            </router-link> -->
        </div>
    </div>
</template>

<script>
export default {
    name: 'Sidebar',
    methods:{
        doLogOut(){
            this.$toast.success("Berhasil melakukan Sign Out", {
                type:'success',
                position:'top-right',
                duration:3000,
                dismissible:true
            });
            localStorage.clear();
            this.$router.push("/");
        }
    }
}
</script>

<style >
.sidebar {
    border-right: 2px solid rgb(240, 240, 240);
    padding: 30px 0px 10px 0px;
    height: 100%;
}

.menu-items > * {
    margin-top: 10px;
    text-align: left;
}

.side-btn {
    border: none;
    padding: 16px 0px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    color: white;
    background-color: transparent;
}

.side-btn:focus {
    outline: none;
}

.side-btn.active {
    position: relative;
    background-color: white;
    color: grey;
    font-weight: 600;
    /* margin-left: 10px; */
    /* border-radius: 30px 0 0 30px; */
}
.router-link-exact-active{
    border-right: 3px solid #046b8eff;
}
</style>