
<template>
  <div id="map">
  <!--In the following div the HERE Map will render-->
    <div id="mapContainer" style="height:430px;width:100%;position: relative;left: 0;top: 0;overflow: hidden" ref="hereMap"></div>
  </div>
</template>

<script>
export default {
  name: "HereMap",
  props: {
    center: Object,
    test:String
    // center object { lat: 40.730610, lng: -73.935242 }
  },
  watch:{
    center:{
      handler(val){
        this.marker.setGeometry(val);
        this.latlong = val;
        this.maps.setCenter(val);
      },
      deep: true,
    },
    
  },
  data() {
    return {
      platform: null,
      apikey: "SNj0BOytgEjBLygUZvdZr-nVdfUlq6eSELESmm72HLI",
      position: "",
      latitude:this.center.lat,
      longitude:this.center.lng,
      marker:"",
      maps:"",
      latlong:""
      // You can get the API KEY from developer.here.com
    };
  },
  mounted() {
    // Initialize the platform object:
    const platform = new window.H.service.Platform({
      apikey: this.apikey
    });
    this.latlong = this.center;
    this.platform = platform;
    this.latitude = this.center.lat;
    this.longitude = this.center.lng;
    this.initializeHereMap();
  },
  beforeDestroy(){
      this.position.setMap(null);
  },
  methods: {
    initializeHereMap() { // rendering map
      
      // var platform = new H.service.Platform({
      //   apikey: this.apikey
      // });

      const mapContainer = this.$refs.hereMap;
      // var staticMapContainer = document.createElement('div');
      // // var defaultLayersSync = platform.createDefaultLayers();

      // mapContainer.style.height = '300px';

      // staticMapContainer.style.position = 'absolute';
      // staticMapContainer.style.width = '600px';
      // staticMapContainer.style.height = '300px';

      // document.getElementById('map').appendChild(mapContainer);
      // document.getElementById('panel').appendChild(staticMapContainer);


      const H = window.H;
      // Obtain the default map types from the platform object
      var maptypes = this.platform.createDefaultLayers();

      // Instantiate (and display) a map object:
      var map = new H.Map(mapContainer, maptypes.vector.normal.map, {
        zoom: 10,
        center: this.latlong,
        pixelRatio: window.devicePixelRatio || 1
        // center object { lat: 40.730610, lng: -73.935242 }
      });

      // add a resize listener to make sure that the map occupies the whole container
      window.addEventListener('resize', () => map.getViewPort().resize());

      // initialize a map that will be synchronised
      // new H.Map(staticMapContainer,
      //   defaultLayersSync.vector.normal.map,{
      //   center: {lat: 53.430, lng: -2.961},
      //   zoom: 7,
      //   pixelRatio: window.devicePixelRatio || 1
      // });
      this.marker = new H.map.Marker(this.center);
      map.addObject(this.marker);

      map.addEventListener("tap", event =>{
          console.log(event);
          // console.log(this.position);
          // map.removeObjects(map.getObjects());
          this.latitude = event.currentPointer.viewportX;
          this.longitude = event.currentPointer.viewportY;
          this.position = map.screenToGeo(
              event.currentPointer.viewportX,
              event.currentPointer.viewportY,
          );
          this.marker.setGeometry(this.position);
          // map.addObject(marker);
          // console.log(this.position);
          this.$emit('getLatitudeMaps', this.position.lat);
          this.$emit('getLongitudeMaps', this.position.lng);
          this.$emit('yesClicked');
          // marker = new H.map.Marker(this.position);
          // map.addObject(marker);

      });

      setTimeout(function () {
          map.getViewPort().resize()
      }, 100);

      addEventListener("resize", () => map.getViewPort().resize());

      // add behavior control
      new H.mapevents.Behavior(new H.mapevents.MapEvents(map));

      // add UI
      H.ui.UI.createDefault(map, maptypes);

      this.maps = map;
      // End rendering the initial map

      // this.synchronizeMaps(map, staticMap);
    }
  },
  getLatitude(){
    // console.log(event);
    this.$emit('getLatitude', this.latitude);
  },
  synchronizeMaps(firstMap, secondMap) {
    // get view model objects for both maps, view model contains all data and
    // utility functions that're related to map's geo state
    var viewModel1 = firstMap.getViewModel(),
        viewModel2 = secondMap.getViewModel();

    // set up view change listener on interactive map
    firstMap.addEventListener('mapviewchange', function() {
      // on every view change take a "snapshot" of a current geo data for
      // interactive map and set this values to the second, non-interactive, map
      viewModel2.setLookAtData(viewModel1.getLookAtData());
    });
  }
};
</script>

<style scoped>
#map {
  width: 60vw;
  min-width: 360px;
  text-align: center;
  margin: 5% auto;
  background-color: #ccc;
}
</style>