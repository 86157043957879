<template>
    <div>
        Customer Care Page
    </div>
</template>

<script>
export default {
    name:"CustomerCarePage"
}
</script>

<style>

</style>