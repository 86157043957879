<template>
  <div class="home">
    <!-- <h1>{{ $t('message') }}</h1> -->
    <div v-if="isLogin">
      <NavbarLogin/>
    </div>

    <div v-else>
      <Navbar/>
    </div>
    <hr style="margin-top:-1px">
        
    <div class="banner-home" style="margin-top:-16px">
      <div class="container">
        <br><br><br><br>
        <div class="text-banner-home mt-10" v-html="$t('WeSpecializeinDeliveringColdStuffs')"> 
          <!-- We Specialize in <br/>Delivering Cold Stuffs  -->
        </div>
        <!-- <div class="row mt-4">
          <div class="col-lg"> -->
            <!-- <div class="box-banner-home-2 text-white"></div> -->
            <!-- <div class="box-banner-home">
              <form> -->
                <!-- <gmap-autocomplete id="refPickupPoint" class="form-control input-home" @place_changed="getPickupPoint" placeholder="Pickup Point"/>
                <gmap-autocomplete id="refDeliveryPoint" class="form-control input-home" @place_changed="getDeliveryPoint" placeholder="Delivery Point"/> -->

                <!-- <vue-bootstrap-typeahead 
                    v-model="addressSearchPickup"
                    :data="addressesPickup"
                    :serializer="s => s.address.label"
                    @hit="getPickupPoint($event)"
                    @input="autoSuggestHereMap(addressSearchPickup, 'pickup')"
                    inputClass="form-control input-home"
                    placeholder="Pickup Point"
                    />

                <vue-bootstrap-typeahead 
                    v-model="addressSearchDelivery"
                    :data="addressesDelivery"
                    :serializer="s => s.address.label"
                    @hit="getDeliveryPoint($event)"
                    @input="autoSuggestHereMap(addressSearchDelivery, 'delivery')"
                    inputClass="form-control input-home"
                    placeholder="Delivery Point"
                    />
                <button type="button" class="button-blue" @click="sendDataToDeliveryOrder">Calculate Shipping Cost</button>
              </form>              
            </div>
          </div>
          <div class="col-lg"></div>
        </div> -->
        <div class="col-lg-6">
          <button type="button" class="button-blue-home" @click="goToDeliveryOrder">{{$t('StartDelivery')}}</button>
        </div>
      </div>
    </div>
    <hr>


    <div class="bg-left-footbar">
      <div class="container mt-5">
        <div class="row">

          <div class="col-md">
            <div class="item-card-home">
              <center class="card-title"><img src="/icon/temperature.png" width="40"/></center>
              <p class="card-text text-center" v-html="$t('RightTemperatureforyourgoods')"></p>
            </div>
          </div>

          <div class="col-md">
            <div class="item-card-home">
              <center class="card-title"><img src="/icon/multiple.png" width="50"/></center>
              <p class="card-text text-center" v-html="$t('Multipledeliveriesin1go!')"></p>
            </div>
          </div>

          <div class="col-md">
            <div class="item-card-home">
              <center class="card-title"><img src="/icon/effctive.png" width="35"/></center>
              <p class="card-text text-center" v-html="$t('EffectiveShippingCostwithOptimizedRoutes')"></p>
            </div>
          </div>

          <div class="col-md">
            <div class="item-card-home">
              <center class="card-title"><img src="/icon/protected.png" width="40"/></center>
              <p class="card-text text-center" v-html="$t('YourgoodsareprotecteduptoIDR1Million')"></p>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-3">
            <div class="item-card-home">
              <center class="card-title"><img src="/icon/ic_safety.png" width="35"/></center>
              <p class="card-text text-center" v-html="$t('Reduceyourdamages&customercomplaints')"></p>
            </div>
          </div>

          <div class="col-md-3">
            <div class="item-card-home">
              <center class="card-title"><img src="/icon/ic_guarantee.png" width="35"/></center>
              <p class="card-text text-center" v-html="$t('Scheduleyourdeliverytofityourschedules')"></p>
            </div>
          </div>

          <!-- <div class="col-md">
            <div class="item-card-home">
              <center class="card-title"><img src="/icon/ic_guarantee.png" width="35"/></center>
              <p class="card-text text-center">Schedule your delivery<br>to fit your schedules</p>
            </div>
          </div> -->

          <div class="col-md-3">
            <div class="item-card-home">
              <center class="card-title"><img src="/icon/ic_pickup.png" width="35"/></center>
              <p class="card-text text-center" v-html="$t('Deliveryupto20Kg/80Lpertrip')"></p>
            </div>
          </div>

          <div class="col-md-3">
            <div class="item-card-home">
              <center class="card-title"><img src="/icon/save.png" width="35"/></center>
              <p class="card-text text-center" v-html="$t('SaveMoney!Reduceyourpackagingcost')"></p>
            </div>
          </div>
        </div>
      </div>
    </div>

    
    <br><br>
    <!--<hr>
    <br><br>

    <div class="font-color-title-home">
      <h4>
        <center><i>Why Choose Us</i></center>
      </h4>
      <br><br>
      <div class="bg-why-choose-us">
        <div class="container">
            <div class="row">
              <div class="col-md" style="padding-top:20px">
                <div class="card shadow-sm center-content" style="width: 19rem;border-radius:20px;">
                  <img class="card-img-top" src="/images/item_choose_1.png" alt="Card image cap">
                </div>
              </div>
              <div class="col-md " style="padding-top:20px">
                <div class="card shadow-sm center-content" style="width: 19rem;border-radius:20px;">
                  <img class="card-img-top" src="/images/item_choose_2.png" alt="Card image cap">
                </div>
              </div>
              <div class="col-md " style="padding-top:20px">
                <div class="card shadow-sm center-content" style="width: 19rem;border-radius:20px;">
                  <img class="card-img-top" src="/images/item_choose_3.png" alt="Card image cap">
                </div>
              </div>
            </div>
        </div>
      </div>
    </div>
    <br><br><br><br> -->

    <!-- <div class="font-color-title-home">
      <h4>
        <center><i>Easy to Order</i></center>
      </h4>
      <div class="bg-easy-to-order">
        <div class="container-fluid ">
          <br><br><br><br><br>
          <div class="row justify-content-md-end" >        
            <div class="col-md-auto" style="margin-top:10px">
              <center>
                  <img src="/images/item_easy_order_1.png" alt="" width="100">  
              </center>              
            </div>

            <div class="col-md-auto" style="margin-top:10px">
              <center>
                <div class="content-item-number-home" >
                    <h1><i>1</i></h1>
                </div>
              </center>              
            </div>            
            
            <div class="col-md-6" >
              <h2><i>Set Pick-Up and Delivery Point</i></h2>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean in nibh consectetur, mollis libero nec, condimentum urna. Suspendisse placerat lacus fermentum mauris mattis, id imperdiet orci scelerisque. Sed velit justo, mattis eu luctus eget, tristique vitae ex. Integer faucibus blandit scelerisque.
              </p>
            </div>
          </div>
          <br>

          <div class="row justify-content-md-end " >            
            <div class="col-md-auto" style="margin-top:10px">
              <center>
                <img src="/images/item_easy_order_2.png" alt="" width="100">
              </center>              
            </div>

            <div class="col-md-auto" style="margin-top:10px">
              <center>
                <div class="content-item-number-home" >
                    <h1><i>2</i></h1>
                </div>
              </center>              
            </div>             
            
            <div class="col-md-6" >
              <h2><i>Route will be optimized by superkul system</i></h2>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean in nibh consectetur, mollis libero nec, condimentum urna. Suspendisse placerat lacus fermentum mauris mattis, id imperdiet orci scelerisque. Sed velit justo, mattis eu luctus eget, tristique vitae ex. Integer faucibus blandit scelerisque.
              </p>
            </div>
          </div>
          <br>

          <div class="row justify-content-md-end" >            
            <div class="col-md-auto" style="margin-top:10px">
              <center>
                <img src="/images/item_easy_order_3.png" alt="" width="100">
              </center>              
            </div>

            <div class="col-md-auto" style="margin-top:10px">
              <center>
                <div class="content-item-number-home">
                    <h1><i>3</i></h1>
                </div>
              </center>              
            </div>            
            
            <div class="col-md-6" >
              <h2><i>Receive delivery details and dispatch your package</i></h2>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean in nibh consectetur, mollis libero nec, condimentum urna. Suspendisse placerat lacus fermentum mauris mattis, id imperdiet orci scelerisque. Sed velit justo, mattis eu luctus eget, tristique vitae ex. Integer faucibus blandit scelerisque.
              </p>
            </div>
          </div> 
        </div>
        
      </div>
    </div> -->
    
    <!-- <div class="bg-left-footbar"></div> -->

    <BottomBar/>

    <!-- <br/> -->
  </div>
</template>

<script>
// @ is an alias to /src
import Navbar from '@/components/Navbar.vue'
import NavbarLogin from '@/components/Navbar_Login.vue'
import BottomBar from '@/components/BottomBar.vue'
import axios from "axios";
// import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'

export default {
  name: 'Home',
  components: {
    Navbar,
    NavbarLogin,
    BottomBar,
    // VueBootstrapTypeahead
    
  },
  data(){
      return{
          isLogin:false,
          pickupPoint:"",
          deliveryPoint:"",
          pickupLatLgn:"",
          deliveryLatLgn:"",
          addressesPickup: [],
          addressSearchPickup: '',
          selectedAddressPickup: null,
          addressesDelivery: [],
          addressSearchDelivery: '',
          selectedAddressDelivery: null,
          pickupLat:"",
          pickupLon:"",
          deliveryLat:"",
          deliveryLon:"",
      }
  },
  created(){
    if(localStorage.getItem('token') != null){
      this.isLogin = true;
    }else{
      this.isLogin = false;
    }
  },  
  methods:{
    async autoSuggestHereMap(query, type){
        axios.get("https://autosuggest.search.hereapi.com/v1/autosuggest?at=-2.4153279,108.8516932&limit=100&lang=id&q="+query+"&apiKey=SNj0BOytgEjBLygUZvdZr-nVdfUlq6eSELESmm72HLI")
        .then((response)=>{
            console.log(response.data);
            if(type == "pickup"){
              this.addressesPickup = response.data.items;
            }else{
              this.addressesDelivery = response.data.items;
            }
            
        }).catch((err)=>{
            console.log(err);
        });
    },
    getPickupPoint(point){
      // this.pickupPoint = point.formatted_address;
      // this.pickupLatLgn = point.geometry.location.lat()+","+point.geometry.location.lng();
      // this.pickupLat = point.geometry.location.lat();
      // this.pickupLon = point.geometry.location.lng();
      // console.log(point);
      // console.log(document.getElementById("refPickupPoint").value);

      this.pickupPoint = point.address.label;
      this.pickupLatLgn = point.position.lat+","+point.position.lng;
      this.pickupLat = point.position.lat;
      this.pickupLon = point.position.lng;
    },
    getDeliveryPoint(point){
      // this.deliveryPoint = point.formatted_address;
      // this.deliveryLatLgn = point.geometry.location.lat()+","+point.geometry.location.lng();
      // this.deliveryLat = point.geometry.location.lat();
      // this.deliveryLon = point.geometry.location.lng();
      // console.log(point);
      // console.log(document.getElementById("refDeliveryPoint").value);

      this.deliveryPoint = point.address.label;
      this.deliveryLatLgn = point.position.lat+","+point.position.lng;
      this.deliveryLat = point.position.lat;
      this.deliveryLon = point.position.lng;
    },
    sendDataToDeliveryOrder(){
      if(this.pickupPoint != "" && this.deliveryPoint != ""){
        this.$router.push({path: '/OrderDelivery', query:{
          deliveryAddress: this.deliveryPoint,
          deliveryLatLon: this.deliveryLatLgn,
          pickupAddress: this.pickupPoint,
          pickupLatLon: this.pickupLatLgn,
          pickupLat:this.pickupLat,
          pickupLon:this.pickupLon,
          deliveryLat:this.deliveryLat,
          deliveryLon:this.deliveryLon
        }});
      }else{
        this.$toast.error("Please fill the fields first!", {
            type:'error',
            position:'top-right',
            duration:3000,
            dismissible:true
        });
      }
      
    },
    goToDeliveryOrder(){
      this.$router.push({path: '/OrderDelivery'});
    }
  }
  
}
</script>
