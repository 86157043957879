<template>
    <div>
        <b-navbar toggleable="lg" type="light">
        <div class="container">
            <b-navbar-brand @click="goToHome" >
                <img src="../assets/images/logo.png" width="157" />
            </b-navbar-brand>

            <b-navbar-toggle target="nav-collapse "></b-navbar-toggle>

            <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav>
            </b-navbar-nav>
                        
            <!-- Right aligned nav items -->
            <b-navbar-nav class="ml-auto">
                <b-nav-item @click="goToPrivacyPolicy" class="mt-2">
                    <div class="menu-nav">
                        {{ $t('privacyPolice') }} 
                    </div>
                </b-nav-item>            
                <b-nav-item @click="goToPricing" class="mt-2">
                    <div class="menu-nav">
                        {{ $t('pricing') }} 
                    </div>
                </b-nav-item>
                <b-nav-item @click="goToFaq" class="mt-2 mr-3">
                    <div class="menu-nav">
                        {{ $t('faq') }} 
                    </div>
                </b-nav-item>
                <b-nav-item>
                   <div class="row" style="margin-right:5px">
                        <!-- <img src="/icon/ic_notif.png" class="image-notif-navbar" > -->
                        <img src="/images/user-profil.png"  class="image-rounded-navbar" alt="Avatar">
                        <b-nav-item-dropdown  text="" right>
                            <template slot="button-content"> <div class="click-link" style="font-size:17px;margin-left:5px">{{ nameUser }}</div></template>
                            <b-dropdown-item @click="goToDashboard">Dashboard</b-dropdown-item>
                            <b-dropdown-item @click="goToOrderDelivery">{{ $t('StartDelivery') }} </b-dropdown-item>
                            <b-dropdown-divider></b-dropdown-divider>
                            <b-dropdown-item @click="signOut">{{ $t('logout') }}</b-dropdown-item>                            
                        </b-nav-item-dropdown>
                        <img src="/icon/arrow-down.png" style="width:18px;height:10px;margin:auto" >
                        <!--<div class="image-text"> Jennie Blackpink <img src="/icon/arrow-down.png" class="image-text" ></div>-->
                    </div>                    
                </b-nav-item>

                <b-nav-item>
                    <select class="form-control selectbox-nav d-none d-md-block" v-model="lang" @change="changeLang(lang)">
                        <option value="en">English</option>
                        <option value="id">Indonesia</option>
                    </select>
                </b-nav-item>
                
            </b-navbar-nav>
            </b-collapse>
        </div>
        </b-navbar>
        <Modal/> 
    </div>
</template>

<script>
import Modal from '@/components/Modal.vue'

export default {
    name: "NavbarLogin",
    components: {
        Modal
    },
    data(){
        return{
            nameUser:"",
            lang:(localStorage.getItem("lang") == null )? "en" : (localStorage.getItem("lang") == "en")?"en":"id",
        }
    },
    created(){
        if(localStorage.getItem('name') != null){
            this.nameUser=localStorage.getItem('name');
        }else{
            this.nameUser="-";
        }
    },
    // mounted(){
    //     window.addEventListener('name-localstorage-changed', (event) => {
    //         this.nameUser = localStorage.getItem('foo-key');
    //         console.log(event.detail.storage);
    //         console.log('jalan');
    //     });
    // },
    methods:{
        goToDashboard(){
            this.$router.push("UserInfo");
        },
        goToHome(){
            this.$router.push("/");
        },
        goToOrderDelivery(){
            this.$router.push("OrderDelivery");
        },
        goToPrivacyPolicy(){
            this.$router.push("PrivacyPolicy");
        },
        goToPricing(){
            this.$router.push("Pricing");
        },
        goToFaq(){
            this.$router.push("FAQ");
        },
        signOut(){
            this.$toast.success("Successfully Sign out", {
                type:'success',
                position:'top-right',
                duration:3000,
                dismissible:true
            });
            localStorage.clear();
            //this.$forceUpdate();
            this.$router.push("/");
            // this.$router.reload();
            this.$router.go(0);
        },
        changeLang(data){
            console.log(data)
            localStorage.setItem("lang", data);

            // this.$router.push("/");
            // this.$router.reload();
            this.$router.go(0);
        }
    }
}
</script>

<style>
    
</style>