<template>
    <div class="card-order-history" @click="goToItemDetail">
        <div class="row">
            <div class="col-md " style="margin-left:5px">
                <div style="float:left;" class="mr-3">
                    <img src="/icon/ic_success.png" alt="">
                </div>
                <div class="font-mid">
                    <!-- <b>ORDER ID #{{dataOrder.id}} </b><br/> -->
                    <b>{{dataOrder.awb_number}}</b><br>
                    <span>{{convertDate(dataOrder.created_at)}}</span><br>
                    <span class="d-md-none">
                        <!-- {{ checkStatusPayment(dataOrder.payment_status) }} -->
                        <!-- <div v-html="checkStatusPayment(dataOrder.payment_status, dataOrder.payment_url_expired_date, dataOrder)"></div> -->
                        <div v-html="checkStatusOrder(dataOrder.status, dataOrder)"></div>
                    </span>
                </div>
            </div>
            <div class="col d-none d-md-block d-lg-block mr-3 mt-2" >
                <span class="d-flex flex-row-reverse">
                    <!-- {{ checkStatusPayment(dataOrder.payment_status) }} -->
                    <!-- <div v-html="checkStatusPayment(dataOrder.payment_status, dataOrder.payment_url_expired_date, dataOrder)"></div> -->
                    <div v-html="checkStatusOrder(dataOrder.status, dataOrder)"></div>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name:"ItemOrderHistory",
    props:['dataOrder', 'dataKey'],
    methods:{
        goToItemDetail(){
            var data = JSON.stringify(this.dataOrder);
            localStorage.setItem('dataOrder', data);
            // this.$router.push({path:"OrderDetail", query:{
            //     dataOrder: this.dataOrder,
            // }});
            if(this.dataKey == "OrderItem"){
                this.$router.push('OrderItemDetail');
            }else{
                localStorage.setItem('statusOrder', this.checkStatusOrderText(this.dataOrder.status));
                this.$router.push('OrderItems');
            }
            
        },
        checkStatusPayment(data, expired_date_payment, dataObject){

            //console.log(expired_date_payment);

            var message="";
            if(dataObject.order_data_raw.type == "api"){
                message = "<span style='font-weight:bold;color:#03AC0E'> H2H </span>";
            }else{
                switch(data){
                    case "PENDING":
                        var dates = this.convertDate(expired_date_payment);
                        var today = new Date(new Date().toString().split('GMT')[0]+' UTC').toISOString().split('.')[0].replace('T',' ');
                        if(today > dates){
                            message = "<span style='font-weight:bold;color:grey'>Expired</span>" ;
                        }else{
                            message = "<span class='label label-warning' style='font-weight:bold;color:#f0ad4e'> Waiting for payment </span>" ;
                        }
                        break;
                    case "APPROVED":
                        message = "<span style='font-weight:bold;color:#03AC0E'> Paid </span>";
                        break;
                    case "REJECTED":
                        message = "<span style='font-weight:bold;color:red'>Failed</span>" ;
                        break;
                    case "EXPIRED":
                        message = "<span style='font-weight:bold;color:grey'>Expired</span>" ;
                        break;
                    default:
                        message = "<span class='label label-warning' style='font-weight:bold;color:yellow'> Waiting for payment </span>";
                }
            }

            return message;
        },
        checkStatusOrder(data, dataObject){
            
            var message="";
            if(dataObject.order_data_raw.type == "api"){
                switch(data){
                    case "On process":
                        message = "<span class='label label-warning' style='font-weight:bold;color:#f0ad4e'> "+this.$t('On Process')+" </span>" ;
                        break;
                    case "Success":
                        message = "<span style='font-weight:bold;color:#03AC0E'> "+this.$t('On Process')+" </span>";
                        break;
                    case "Failed":
                        message = "<span style='font-weight:bold;color:red'>"+this.$t('Please Contact Admin')+"</span>" ;
                        break;
                    case "Waiting for confirmation":
                        message = "<span style='font-weight:bold;color:grey'>"+this.$t('On Process')+"</span>" ;
                        break;
                    case "Cancelled":
                        message = "<span style='font-weight:bold;color:red'"+this.$t('Please Contact Admin')+"</span>" ;
                        break;
                    case "Order delivered":
                        message = "<span style='font-weight:bold;color:#03AC0E'>"+ this.$t('Trip Completed') +"</span>";
                        break;
                    case "Trip ended":
                        message = "<span style='font-weight:bold;color:#03AC0E'>"+ this.$t('Trip Completed') +"</span>";
                        break;
                    case "Order canceled":
                        message = "<span style='font-weight:bold;color:red'>"+this.$t('Please Contact Admin')+"</span>" ;
                        break;
                    case "Trip incomplete":
                        message = "<span style='font-weight:bold;color:purple'>"+this.$t('Trip Incomplete')+"</span>" ;
                        break;
                    default:
                        message = "<span class='label label-warning' style='font-weight:bold;color:#f0ad4e'>"+this.$t('On Process')+"</span>" ;
                }
            }else{
               switch(data){
                    case "On process":
                        message = "<span class='label label-warning' style='font-weight:bold;color:#f0ad4e'> "+this.$t('Paid & On Process')+"</span>" ;
                        break;
                    case "Success":
                        message = "<span style='font-weight:bold;color:#03AC0E'>"+this.$t('Paid & On Process')+"</span>";
                        break;
                    case "Failed":
                        message = "<span style='font-weight:bold;color:red'>"+this.$t('Please Contact Admin')+"</span>" ;
                        break;
                    case "Waiting for confirmation":
                        message = "<span style='font-weight:bold;color:grey'>"+this.$t('Waiting for Payment')+"</span>" ;
                        break;
                    case "Cancelled":
                        message = "<span style='font-weight:bold;color:red'>"+this.$t('Please Contact Admin')+"</span>" ;
                        break;
                    case "Order delivered":
                        message = "<span style='font-weight:bold;color:#03AC0E'> "+this.$t('Paid & Trip Completed')+" </span>";
                        break;
                    case "Trip ended":
                        message = "<span style='font-weight:bold;color:#03AC0E'> "+this.$t('Paid & Trip Completed')+"</span>";
                        break;
                    case "Order canceled":
                        message = "<span style='font-weight:bold;color:red'>"+this.$t('Please Contact Admin')+"</span>" ;
                        break;
                    case "Trip incomplete":
                        message = "<span style='font-weight:bold;color:purple'>"+this.$t('Paid but Trip Incomplete')+"</span>" ;
                        break;
                    default:
                        message = "<span class='label label-warning' style='font-weight:bold;color:#f0ad4e'>"+this.$t('Paid & On Process')+"</span>" ;
                } 
            }
            

            return message;
        },
        checkStatusOrderText(data){
            
            var message="";
            switch(data){
                case "On process":
                    message = "OK" ;
                    break;
                case "Success":
                    message = "NOT OK";
                    break;
                case "Failed":
                    message = "NOT OK" ;
                    break;
                case "Waiting for confirmation":
                    message = "NOT OK" ;
                    break;
                case "Cancelled":
                    message = "NOT OK" ;
                    break;
                case "Order delivered":
                    message = "OK";
                    break;
                case "Trip ended":
                    message = "OK";
                    break;
                case "Order canceled":
                    message = "NOT OK" ;
                    break;
                case "Trip incomplete":
                    message = "NOT OK" ;
                    break;
                default:
                    message = "OK" ;
            }

            return message;
        },
        convertDate(date){
            var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear(),
            hours = d.getHours(),
            minute = d.getMinutes();

            if (month.length < 2) 
                month = '0' + month;
            if (day.length < 2) 
                day = '0' + day;

            return [year, month, day].join('-') + " "+ [hours, minute].join(":");
      }
    },
    
}
</script>

<style>
    .card-order-history{
        border: 1px solid;
        padding: 8px;
        border-radius: 50px;
        justify-content: center;
        align-items: center;
        border-color: #808080ff;
        margin-top: 10px;
        cursor: pointer;
    }

    .text-right{
        text-align: right;
    }

    .bg-blue{
        background-color: aqua;
    }
</style>