<template>
<div class="modal-style">
    <b-modal ref="login-modal" body-class="modal-content-login" id="login_modal" size="lg" centered hide-footer hide-header>
        <div class="">
            <div class="row">
                <div class="col-lg-5"> </div>
                <div class="col-md form-login">
                    <div class="text-login">{{$t('Login')}}</div>
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="input_username_login" :placeholder="$t('Email')" style="border-radius: 30px;">
                    </div>
                    <div class="form-group">
                        <input type="password" class="form-control" v-model="input_password_login" :placeholder="$t('Password')" style="border-radius: 30px;">
                    </div>

                    <div class="blue-text" style="cursor:pointer" @click="showModalForgetPassword">{{$t('Forget Password')}}</div>
                    
                    <div class="form-group mt-3">
                        <button class="menu-nav-button-start" style="border-radius: 30px; width: 100%" @click="doLogin">{{$t('Login')}}</button>
                    </div>

                    <div class="text-center pad-top-5 ">{{$t('New user?')}} <span class="blue-text" style="cursor:pointer" @click="showModalRegister">{{$t('Register here!')}}</span> </div>
                </div>
            </div>
        </div>
    </b-modal>

    <b-modal ref="forget-modal" body-class="modal-content-forget" id="forget-modal" size="lg" centered hide-footer hide-header>
        <div class="">
            <div class="row">
                <div class="col-lg-5"> </div>
                <div class="col-md form-forget">
                    <div class="text-login">{{$t('Forget Password')}}</div>
                    <div class="form-group" style="margin-top:50px">
                        <input type="text" class="form-control" v-model="input_email_forget" :placeholder="$t('Email')" style="border-radius: 30px;">
                    </div>
                                        
                    <div class="form-group mt-3">
                        <button class="menu-nav-button-start" style="border-radius: 30px; width: 100%" @click="doForgetPassword">{{$t('Forget Password')}}</button>
                    </div>

                </div>
            </div>
        </div>
    </b-modal>

    

    <b-modal ref="register-modal" body-class="modal-content-register" id="register-modal" size="lg" centered hide-footer hide-header>
        <div class="">
            <div class="row">
                <div class="col-md-5"> </div>
                <div class="col-md form-register">
                    <div class="text-register">{{$t('Create your account')}}</div>
                    <div class="form-group">
                        <input type="text" class="form-control" :placeholder="$t('Customer name')" v-model="input_fullname_register" style="border-radius: 30px;">
                    </div>
                    <div class="form-group">
                        <input type="text" class="form-control" :placeholder="$t('Email')" v-model="input_email_register" @input="formatEmail(input_email_register, $event)" style="border-radius: 30px;">
                    </div>
                    <div class="form-group">
                        <input type="text" class="form-control" :placeholder="$t('Phone Number')" v-model="input_phonenumber_register" @input="formatPhoneNumber(input_phonenumber_register, $event)" style="border-radius: 30px;">
                    </div>
                    <div class="form-group">
                        <input type="password" class="form-control" :placeholder="$t('Password')" v-model="input_password_register" style="border-radius: 30px;">
                    </div>
                    <div class="form-group">
                        <input type="password" class="form-control" :placeholder="$t('Password Confirmation')" v-model="input_password_confirmation_register" style="border-radius: 30px;">
                    </div>

                    <div class="form-group">
                        {{$t('Would you like to apply for a postpay account?')}}
                        <select class="form-control" style="border-radius: 30px;" v-model="customer_type">
                            <option value="prepaid">{{$t('NO')}}</option>
                            <option value="postpaid">{{$t('YES')}}</option>
                        </select>
                    </div>
                    
                    <div class="form-group mt-3">
                        <button class="menu-nav-button-start" style="border-radius: 30px; width: 100%" @click="registerUser">{{$t('Register')}}</button>
                    </div>

                     <div class="text-center pad-top-3" >{{$t('Already have an account?')}} <div class="blue-text" style="cursor:pointer">Login</div></div>
                </div>
            </div>
        </div>
    </b-modal>

</div>
    
</template>

<script>
import axios from "axios";
import {HTTP} from '@/constants'

export default {
    name: 'Modal',
    //props:['dataGmap'],
    data(){
        return{
            input_username_login:"",
            input_password_login:"",
            input_email_forget:"",
            input_fullname_register:"",
            input_email_register:"",
            input_phonenumber_register:"",
            input_password_register:"",
            input_password_confirmation_register:"",
            link_gmap:"",
            validatePhone:"0",
            validateEmail:"0",
            customer_type:"prepaid",
        }
    },
    created(){
        //console.log(this.dataGmap);
    },
    methods:{
        doLogin(){
            this.$toast.warning(this.$t('Wait, on process!'), {
                type:'warning',
                position:'top-right',
                duration:3000,
                dismissible:true
            });

            axios.post(HTTP+"api/v1/login", 
            {
                email:this.input_username_login, 
                password:this.input_password_login
            })
            .then((response)=>{
                if(response.data.status == "success"){
                    this.$toast.success(response.data.message, {
                        type:'success',
                        position:'top-right',
                        duration:3000,
                        dismissible:true
                    });

                    localStorage.setItem('token', response.data.token);
                    localStorage.setItem('name', response.data.data.name);
                    localStorage.setItem('refId', response.data.data.reference_id);
                    this.$router.push("UserInfo");
                    //localStorage.clear();
                    //console.log(response.data.data.name);
                    //console.log(response.data.token);
                    //console.log(response.data.data.reference_id);
                }else{
                    this.$toast.error(response.data.message, {
                        type:'error',
                        position:'top-right',
                        duration:3000,
                        dismissible:true
                    });
                }
                
            }).catch((err)=>{
                console.log(err);
            });
        },
        doForgetPassword(){
            this.$toast.warning(this.$t('Wait, on process!'), {
                type:'warning',
                position:'top-right',
                duration:3000,
                dismissible:true
            });
            
            axios.post(HTTP+"api/v1/forget-password", {
                email:this.input_email_forget
            }).then((response)=>{
                if(response.data.status == "success"){
                    this.$toast.success(response.data.message, {
                        type:'success',
                        position:'top-right',
                        duration:3000,
                        dismissible:true
                    });

                    this.$refs['forget-modal'].hide();
                }else{
                    this.$toast.error(response.data.message, {
                        type:'error',
                        position:'top-right',
                        duration:3000,
                        dismissible:true
                    });
                }
            }).catch((err)=>{
                console.log(err);
            });
        },
        registerUser(){

            if(this.validatePhone == "0"){
                this.$toast.error(this.$t('Invalid phone number format!'), {
                    type:'error',
                    position:'top-right',
                    duration:3000,
                    dismissible:true
                });
                return;
            }

            if(this.validateEmail == "0"){
                this.$toast.error(this.$t('Invalid Email format!'), {
                    type:'error',
                    position:'top-right',
                    duration:3000,
                    dismissible:true
                });
                return;
            }

            this.$toast.warning(this.$t('Wait, on process!'), {
                type:'warning',
                position:'top-right',
                duration:3000,
                dismissible:true
            });

            axios.post(HTTP+"api/v1/signup", {
                name: this.input_fullname_register,
                phone_number:this.input_phonenumber_register,
                email:this.input_email_register,
                password:this.input_password_register,
                password_confirmation:this.input_password_confirmation_register,
                customer_type:this.customer_type,
            }).then((response)=>{
                if(response.data.status == "success"){
                    this.$toast.success(response.data.message, {
                        type:'success',
                        position:'top-right',
                        duration:3000,
                        dismissible:true
                    });

                    this.$refs['register-modal'].hide();
                    this.input_fullname_register = "";
                    this.input_phonenumber_register = "";
                    this.input_email_register = "";
                    this.input_password_register = "";
                    this.input_password_confirmation_register = "";
                }else{
                    this.$toast.error(response.data.message, {
                        type:'error',
                        position:'top-right',
                        duration:3000,
                        dismissible:true
                    });
                }

            }).catch((err)=>{
                console.log(err);
            });
        },
        hideModalLogin(){
            this.$refs['login-modal'].hide()
            //this.showModalRegister()
        },
        hideModalRegister(){
            this.$refs['register-modal'].hide()
            //this.showModalLogin()
        },
        hideModalForgetPassword(){
            this.$refs['forget-modal'].hide();
        },
        showModalForgetPassword(){
            // this.$refs['login-modal'].hide();
            this.hideModalLogin();
            this.hideModalRegister();
            this.$refs['forget-modal'].show();
        },
        showModalLogin(){
            this.$refs['login-modal'].show()
            this.hideModalRegister();
            this.hideModalForgetPassword();
        },
        showModalRegister(){
            this.$refs['register-modal'].show()
            this.hideModalForgetPassword();
            this.hideModalLogin()
        },
        reloadIframe(){
            document.getElementById("myFrame").remove();
            var html = '<iframe id="myFrame" :src="this.dataGmap" allow="geolocation" height="490px" width="100%" title="Iframe Example"></iframe>';
            document.getElementById("gmapIframe").html = html;
        },
        formatPhoneNumber(phoneNumberString, el) {
            var elmnt = el.target;
            var div = document.createElement('div');

            var testdiv = '<div><div style="color:red;">'+this.$t("Invalid phone number format!")+'. '+this.$t("Ex")+': +6281234567890</div></div>'
            div.innerHTML = testdiv;
            var match =  /^(^\+628)\d{7,20}$/.test(phoneNumberString);
            if (match) {
                if(elmnt.nextElementSibling != null){
                    elmnt.nextElementSibling.remove();
                }
                this.validatePhone = "1";
            }else{
                if(elmnt.nextElementSibling == null){
                    elmnt.parentNode.appendChild(div);
                }
                this.validatePhone = "0";
            }
            return null;            
        },
        formatEmail(email, el){
            var elmnt = el.target;
            var div = document.createElement('div');

            var testdiv = '<div><div style="color:red;">'+this.$t("Invalid Email format!")+'. '+this.$t("Ex")+': budi@gmail.com</div></div>'
            div.innerHTML = testdiv;

            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            var match = re.test(String(email).toLowerCase());
            if(match){
                if(elmnt.nextElementSibling != null){
                    elmnt.nextElementSibling.remove();
                }
                this.validateEmail = "1";
            }else{
                if(elmnt.nextElementSibling == null){
                    elmnt.parentNode.appendChild(div);
                }
                this.validateEmail = "0";
            }
            return null;
        }
        // getPickupPoint(point){
        //     this.pickupPoint = point;
        //     console.log(point.formatted_address);
        //     console.log(point.name);
        //     console.log(point);
        //     this.lokasiPickup = point.formatted_address;
        //     this.latlong = point.geometry.location.lat()+","+point.geometry.location.lng();
        //     this.longitude = point.geometry.location.lng();
        //     this.latitude = point.geometry.location.lat();
        //     //this.reloadIframe();
        //     //this.deliveryRoutes[id].provinsi = point.address_components[point.address_components.length - 3].long_name
        // }
        
    }
}
</script>

<style scoped>
/deep/ .modal-content-login {
  background-image: url('../assets/images/modal-login.png');
  background-size: cover;
  background-color: transparent;
  border: 0;
  border-radius: 50px;
  width: 100%;
  height: 495px;
  background-position: right;
}

/deep/ .modal-content-register {
  background-image: url('../assets/images/modal-register.png');
  /* background-size: cover; */
  background-color: transparent;
  border: 0;
  border-radius: 50px;
  width: 100%;
  height: 555px;
  background-position: right;
}

/deep/ .modal-content-forget {
  background-image: url('../assets/images/modal-forget.png');
  background-size: cover;
  background-color: transparent;
  border: 0;
  border-radius: 50px;
  width: 100%;
  height: 495px;
  background-position: right;
}

/deep/ .modal-content-gmap {
  background-color: transparent;
  border: 0;
  width: 100%;
  height: 495px;    
}

.form-register{
    padding: 0px 50px 130px 0px;
}

.text-login{
    font-size: 35px;
    color: #b2deeaff;
    margin: 10px;
}

.text-register{
    font-size: 25px;
    color: #b2deeaff;
    margin: 7px;
}

.pad-top-5{
    padding-top: 100px;
}

.pad-top-3{
    padding-top: 10px;
}

.blue-text{
    color: #046b8eff;
}

</style>