import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import UserInfo from '../views/UserInfo.vue'
import OrderHistory from '../views/OrderHistory.vue'
import OrderItemDetail from '../views/OrderItemDetail.vue'
import OrderItems from '../views/OrderItems.vue'
import OrderDelivery from '../views/OrderDelivery.vue'
import UserPayment from '../views/UserPayment.vue'
import UserSettings from '../views/UserSettings.vue'
import CustomerCarePage from '../views/CustomerCarePage.vue'
import FAQPage from '../views/FAQPage.vue'
import PricingPage from '../views/PricingPage.vue'
import PrivacyPolicyPage from '../views/PrivacyPolicyPage.vue'
import TermConditionPage from '../views/TermConditionPage.vue'
import OrderItemDetailIframe from '../views/OrderItemDetailIframe.vue'
import ExportHistoryPage from '../views/ExportHistoryPage.vue';
// import i18n from '../i18n'

Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   redirect: "/"+i18n.locale,
  // },
  // {
  //   path: '/:lang',
  //   component:{
  //     render (c) {
  //       return c('router-view')
  //     } 
  //   },
  //   children: [
  //     {
  //       path: '/',
  //       name: 'Home',
  //       component: Home
  //     },
  //     {
  //       path: 'about',
  //       name: 'About',
  //       component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  //     },{
  //       path:'UserInfo',
  //       name:'UserInfo',
  //       component: UserInfo
  //     },{
  //       path:'OrderHistory',
  //       name:'OrderHistory',
  //       component: OrderHistory
  //     },{
  //       path:'OrderItemDetail',
  //       name:'OrderItemDetail',
  //       component: OrderItemDetail
  //     },{
  //       path:'/OrderItems',
  //       name:'OrderItems',
  //       component: OrderItems
  //     },{
  //       path:'OrderDelivery',
  //       name:'OrderDelivery',
  //       component: OrderDelivery
  //     },{
  //       path:'/UserPayment',
  //       name:'UserPayment',
  //       component: UserPayment
  //     },{
  //       path:'UserSettings',
  //       name:'UserSettings',
  //       component: UserSettings
  //     },{
  //       path:'CustomerCare',
  //       name:'CustomerCarePage',
  //       component: CustomerCarePage
  //     },{
  //       path:'FAQ',
  //       name:'FAQPage',
  //       component: FAQPage
  //     },{
  //       path:'Pricing',
  //       name:'PricingPage',
  //       component: PricingPage
  //     },{
  //       path:'PrivacyPolicy',
  //       name:'PrivacyPolicyPage',
  //       component: PrivacyPolicyPage
  //     },{
  //       path:'TermCondition',
  //       name:'TermConditionPage',
  //       component: TermConditionPage
  //     },{
  //       path:'OrderItemDetails',
  //       name:'OrderItemDetails',
  //       component: OrderItemDetailIframe
  //     },{
  //       path:'exporthistory',
  //       name:'ExportHistoryPage',
  //       component: ExportHistoryPage,
  //     }
  //   ]
  // }

  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },{
    path:'/UserInfo',
    name:'UserInfo',
    component: UserInfo
  },{
    path:'/OrderHistory',
    name:'OrderHistory',
    component: OrderHistory
  },{
    path:'/OrderItemDetail',
    name:'OrderItemDetail',
    component: OrderItemDetail
  },{
    path:'/OrderItems',
    name:'OrderItems',
    component: OrderItems
  },{
    path:'/OrderDelivery',
    name:'OrderDelivery',
    component: OrderDelivery
  },{
    path:'/UserPayment',
    name:'UserPayment',
    component: UserPayment
  },{
    path:'/UserSettings',
    name:'UserSettings',
    component: UserSettings
  },{
    path:'/CustomerCare',
    name:'CustomerCarePage',
    component: CustomerCarePage
  },{
    path:'/FAQ',
    name:'FAQPage',
    component: FAQPage
  },{
    path:'/Pricing',
    name:'PricingPage',
    component: PricingPage
  },{
    path:'/PrivacyPolicy',
    name:'PrivacyPolicyPage',
    component: PrivacyPolicyPage
  },{
    path:'/TermCondition',
    name:'TermConditionPage',
    component: TermConditionPage
  },{
    path:'/OrderItemDetails',
    name:'OrderItemDetails',
    component: OrderItemDetailIframe
  },{
    path:'/exporthistory',
    name:'ExportHistoryPage',
    component: ExportHistoryPage,
  }
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
