<template>
    <div>
        <div v-if="isLogin">
            <NavbarLogin/>
        </div>

        <div v-else>
            <Navbar/>
        </div>
        
        <div class="orderDelivery">
            <div class="container">            
                <div style="margin-top:50px;margin-bottom:50px">
                    <h2>{{$t('Order Delivery')}}</h2>
                    <p>{{$t('If you plan to book today, please log in to your account first!')}}</p>
                    <p>{{$t('Orders submitted after 16:00 will be processed before 9:00 the next day')}}</p>
                </div>
                
                <!-- <HereMap  :center="center" @getLatitudeMaps="getLatitude($event)"  @getLongitudeMaps="getLongitude($event)" @yesClicked="getStreetAddressFrom()" /> -->

                <div>
                    <div> {{$t('Please choose our service (Required)')}} <span style="font-size:12px;color:red;">({{$t('Required')}})</span></div>
                    <div class="radio-toolbar" style="margin-top:10px;">
                        <span style="width:450px">
                            <input type="radio" id="radioDelivery" name="radioServices" v-model="services" value="Instant" disabled> 
                            <div class="row">
                                <label for="radioDelivery" class="radio-disabled"> <img src="/icon/ic_delivery.png" alt=""> {{$t('Instant Delivery - Nearest driver available now')}}</label>
                                <h6 style="margin-top:5px;margin-left:20px">{{$t('Service to go live in Q2 2022')}}</h6>
                            </div>
                            
                            
                            <div style="font-size:10px;margin-top:10px;">
                                {{$t('We will send our nearest driver right now to pick up your order')}}
                            </div>
                        </span>
                        
                        <br>

                        <span style="width:450px">
                            <input type="radio" id="radioReguler"  name="radioServices" v-model="services" value="Sameday">
                            <label for="radioReguler" style="padding-right:90px"><img src="/icon/ic_reguler.png" alt=""> {{$t('Reguler Delivery - Sameday delivery')}} </label> 
                            <div style="font-size:10px;margin-top:10px;">
                               {{$t('We will send our nearest driver on schedule')}}
                            </div>
                            <br>
                            <div v-if="services == 'Sameday'">
                                <div class="row">
                                    <div class="col-md-3">
                                        <div style="margin-bottom:10px">{{$t('Please Select Date')}}</div>
                                        <b-form-datepicker id="example-datepicker" :min="minDate" :max="maxDate" v-model="dateForm" :placeholder="$t('No date selected')" class="mb-2 input-datetime-picker"></b-form-datepicker>
                                        <!-- <p>Value: '{{ dateForm }}'</p> -->
                                    </div>
                                    <div class="col-md-3">
                                        <div> {{$t('Please Select Time')}}</div>
                                        <!-- <b-form-timepicker v-model="timeForm" locale="id" class="input-datetime-picker"></b-form-timepicker> -->
                                        <input type="text" class="form-control input-time" v-model="timeForm" placeholder="Ex: 04:00"  @input="formatDate(timeForm, $event)">
                                        <!-- <div class="mt-2">Value: '{{ timeForm }}'</div> -->
                                    </div>
                                    
                                </div>
                            </div>
                        </span>                
                    </div>
                    <div v-if="$v.services.$error">
                        <div style="color:red;">{{$t('Services required')}}</div>
                    </div>
                    
                </div>
                <br>

                <div>
                    <div>{{$t('Required temperature in Celsius (-22℃ to 10℃)')}}</div>
                    <div class="row">
                        <div class="col-md-6">
                            <input type="number" class="form-control input-item-delivery"  v-model="temperaturMin" min="-22" max="10" :placeholder="$t('Temperature')" @change="checkTemperature(temperaturMin, 'min')" required>   
                            <!-- <div v-if="$v.temperaturMin.$error">
                                <div style="color:red;">Temperature required</div>
                            </div> -->
                        </div>

                        <!-- <div class="col-md-3">
                            <input type="number" class="form-control input-item-delivery" :class="{ 'input-item-delivery--error': $v.temperaturMax.$error }" v-model="temperaturMax" min="-22" max="10" placeholder="Max temperature" @change="checkTemperature(temperaturMax, 'max')" required>
                            <div v-if="$v.temperaturMax.$error">
                                <div style="color:red;">Max Temperature required</div>
                            </div>
                        </div> -->
                    </div>
                    <div style="font-size:10px;margin-top:10px;">
                        {{$t('If there is no need to turn on the freezer, please leave this field as blank')}} 
                    </div>                    
                </div>
                <br><br>
                
                <div style="margin-bottom:0px">
                    <h3>{{$t('Delivery Route')}}</h3>
                </div>            
                
                <ul class="progressbar orderDelivery" >
                    <li class="" v-for="(deliveryRoute, index) in deliveryRoutes" :set="v = $v.deliveryRoutes.$each[index]" :key="index">
                        <div v-if="deliveryRoute.formType == 'pickup'">
                            <div class="content-delivery">
                                <div class="item-card-delivery">

                                    <div>
                                        <div>
                                            <div class="content-number">{{index+1}}</div> 
                                            <div class="content-number-text">
                                                <b> {{$t('From')}}</b>
                                            </div>
                                        </div>                                        
                                    </div>
                                    <br><br><br>
                                    
                                    <div class="row">
                                        <div class="col-md">
                                            <div>{{$t('Name (Required)')}} <span style="font-size:12px;color:red;">({{$t('Required')}})</span></div>
                                            <input type="text" class="form-control input-phone-delivery" v-model="deliveryRoute.nama" placeholder="Ex: John Doe" :class="{ 'input-phone-delivery--error': v.nama.$error }" >
                                            <div v-if="v.nama.$error">
                                                <div style="color:red;">{{$t('Name is required')}}</div>
                                            </div>
                                        </div>
                                        <div class="col-md">
                                            <div>{{$t('Phone Number (Required)')}} <span style="font-size:12px;color:red;">({{$t('Required')}})</span></div>
                                            <input type="text" class="form-control input-phone-delivery" v-model="deliveryRoute.nomorTelepon" placeholder="Ex: +628123456789"  :class="{ 'input-phone-delivery--error': v.nomorTelepon.$error }" @change="formatPhoneNumber(deliveryRoute.nomorTelepon, $event, index)" @input="formatPhoneNumber(deliveryRoute.nomorTelepon, $event, index)">
                                            <div v-if="v.nomorTelepon.$error">
                                                <div style="color:red;">{{$t('Phone Number is required')}}</div>
                                            </div>
                                            <div v-if="v.validatePhone.$error">
                                                <div style="color:red;">{{$t('Format Phone Number invalid!')}}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr>

                                    <div class="col-md">
                                        <!-- <div style="visibility:hidden;">d</div> -->
                                        <center><span style="font-size:12px;color:red;">({{$t('Required')}})</span></center>
                                        <button type="button" class="button-blue" v-b-modal="'gmap-modal'+index" style="width:100%">{{$t('Click here to point the location')}}</button>
                                        <!-- <button type="button" class="button-blue" @click="doCheckLocation(index)">Check Location</button> -->
                                    </div>
                                    <br>

                                    <div class="row">
                                        <div class="col-md">
                                            <div>{{$t('Address (Locality/Street) (Required)')}} <span style="font-size:12px;color:red;">({{$t('Required')}})</span></div>
                                            <input type="text" class="form-control input-address-delivery" :class="{ 'input-address-delivery--error': v.alamat.$error }" v-model="deliveryRoute.alamat" placeholder="ex: Jalan Kartini Raya" >
                                            <div v-if="v.alamat.$error">
                                                <div style="color:red;">{{$t('Address is required')}}</div>
                                            </div>
                                        </div>
                                        <div class="col-md">
                                            <div>{{$t('House/Floor/Unit Number (Required)')}} <span style="font-size:12px;color:red;">({{$t('Required')}})</span></div>
                                            <input type="text" class="form-control input-address-delivery" :class="{ 'input-address-delivery--error': v.apartemen.$error }" v-model="deliveryRoute.apartemen" placeholder="Ex: Lt 2 No.12" >
                                            <div v-if="v.apartemen.$error">
                                                <div style="color:red;">{{$t('House/Floor/Unit Number is required')}}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <br>

                                    <div class="row">
                                        <div class="col-md">
                                            <div>{{$t('State (Required)')}} <span style="font-size:12px;color:red;">({{$t('Required')}})</span></div>
                                            <select class="form-control selectbox-address-delivery" :class="{ 'input-address-delivery--error': v.provinsi.$error }" @change="getCity(deliveryRoute.provinsi, index)"  aria-label="Default select example" v-model="deliveryRoute.provinsi">
                                                <!-- <option value="Select State">Select State</option> -->
                                                <option v-for="dataProvice in dataProvinces" :key="dataProvice.id" :value="dataProvice.province">{{ dataProvice.province }}</option>
                                            </select>
                                            <div v-if="v.provinsi.$error">
                                                <div style="color:red;">{{$t('State is required')}}</div>
                                            </div>
                                        </div>
                                        <div class="col-md">
                                            <div>{{$t('City (Required)')}} <span style="font-size:12px;color:red;">({{$t('Required')}})</span></div>
                                            <select class="form-control selectbox-address-delivery" :class="{ 'input-address-delivery--error': v.kota.$error }"  aria-label="Default select example" v-model="deliveryRoute.kota">
                                                <!-- <option value="Select City">Select City</option> -->
                                                <option v-for="dataCity in deliveryRoute.dataCitys" :key="dataCity.id" :value="dataCity.city">{{ dataCity.city }}</option>
                                            </select>
                                            <div v-if="v.kota.$error">
                                                <div style="color:red;">{{$t('City is required')}}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <br>

                                    <div class="row">
                                        <div class="col-md-6">
                                            <div>{{$t('Postal Code (Required)')}} <span style="font-size:12px;color:red;">({{$t('Required')}})</span></div>
                                            <input type="number" maxlength="5" class="form-control input-address-delivery" :class="{ 'input-address-delivery--error': v.kodePos.$error }" v-model="deliveryRoute.kodePos" placeholder="Ex: 16320" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);">
                                            <div v-if="v.kodePos.$error">
                                                <div style="color:red;">{{$t('Postal Code is required')}}</div>
                                            </div>
                                        </div>
                                        <div class="col-md">
                                            <!-- <div style="visibility:hidden;">d</div>
                                            <button type="button" class="button-blue" v-b-modal="'gmap-modal'+index" >Point your location here</button> -->
                                            <!-- <button type="button" class="button-blue" @click="doCheckLocation(index)">Check Location</button> -->
                                        </div>
                                    </div>
                                    <br>

                                    <hr>

                                    <!-- <div class="row">
                                        <div class="col-md">
                                            <div>Latitude <span style="font-size:12px;color:red;">(Required)</span></div>
                                            <input type="text" class="form-control input-address-delivery" :class="{ 'input-address-delivery--error': v.latitude.$error }" v-model="deliveryRoute.latitude" placeholder="Ex: -6.4610789" >
                                            <div v-if="v.latitude.$error">
                                                <div style="color:red;">Latitude is required</div>
                                            </div>
                                        </div>
                                        <div class="col-md">
                                            <div>Longitude <span style="font-size:12px;color:red;">(Required)</span></div>
                                            <input type="text" class="form-control input-address-delivery" :class="{ 'input-address-delivery--error': v.longitude.$error }" v-model="deliveryRoute.longitude" placeholder="Ex: 106.8043952" >
                                            <div v-if="v.longitude.$error">
                                                <div style="color:red;">Latitude is required</div>
                                            </div>
                                        </div>
                                    </div> -->
                                    <!-- <div class="click-link" v-b-modal="'gmap-modal'+index">
                                        Click here to check location on google maps
                                    </div> -->
                                    <br>
                                    
                                    <div class="row">
                                        <div class="col-md">
                                            <div>{{$t('Address Notes (Optional)')}} <span style="font-size:12px">({{$t('Optional')}})</span></div>
                                            <input type="text" class="form-control input-address-delivery" v-model="deliveryRoute.landmark" placeholder="Ex: Samping Indomaret Cimanggis" >
                                        </div>
                                        <div class="col-md">
                                            <div>{{$t('Order Description (Optional)')}} <span style="font-size:12px">({{$t('Optional')}})</span></div>
                                            <input type="text" class="form-control input-item-delivery" v-model="orderDescription" placeholder="Ex: Buah Jeruk 1 Box" >                                            
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                            
                        <div v-else>
                            <div class="content-delivery">
                                <div class="item-card-delivery">
                                    <div>
                                        <div class="button-close" @click="deleteDeliveryRoute(index)">X</div>
                                        <div>
                                            <div class="content-number">{{index+1}}</div> 
                                            <div class="content-number-text">
                                                <b>{{$t('To')}}</b>
                                            </div>
                                        </div>                                        
                                    </div>
                                    <br><br><br>
                                    
                                    <!-- <div class="row">
                                        <div class="col-md">
                                            <div >Delivery Address <span style="font-size:12px">(Required)</span></div>
                                            <gmap-autocomplete id="refDeliveryPoint" class="form-control input-address-delivery" :class="{ 'input-address-delivery--error': v.alamat.$error }" :value="deliveryRoute.alamat" @place_changed="getDeliverPoint($event, index)" placeholder="ex: Jl. Nusantara. Komp. Ruko Lokasari No. 7A Hankam"/>
                                            <div v-if="v.alamat.$error">
                                                <div style="color:red;">Delivery Address is required</div>
                                            </div>
                                        </div>                                        
                                    </div>                                    
                                    <br> -->
                                    
                                    <div class="row">
                                        <div class="col-md">
                                            <div>{{$t('Name (Required)')}} <span style="font-size:12px;color:red;">({{$t('Required')}})</span></div>
                                            <input type="text" class="form-control input-phone-delivery" :class="{ 'input-phone-delivery--error': v.nama.$error }" v-model="deliveryRoute.nama" placeholder="Ex: John Doe" >
                                            <div v-if="v.nama.$error">
                                                <div style="color:red;">{{$t('Name is required')}}</div>
                                            </div>
                                        </div>
                                        <div class="col-md">
                                            <div>{{$t('Phone Number (Required)')}} <span style="font-size:12px;color:red;">({{$t('Required')}})</span></div>
                                            <input type="text" class="form-control input-phone-delivery" :class="{ 'input-phone-delivery--error': v.nomorTelepon.$error }" @input="formatPhoneNumber(deliveryRoute.nomorTelepon, $event, index)" v-model="deliveryRoute.nomorTelepon" placeholder="Ex: +628123456789" >
                                            <div v-if="v.nomorTelepon.$error">
                                                <div style="color:red;">{{$t('Phone Number is required')}}</div>
                                            </div>
                                            <div v-if="v.validatePhone.$error">
                                                <div style="color:red;">{{$t('Format Phone Number invalid!')}}. {{$t('Ex')}}: +6281234567890</div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <hr>

                                    <div class="col-md">
                                        <!-- <div style="visibility:hidden;">d</div> -->
                                        <center><span style="font-size:12px;color:red;">({{$t('Required')}})</span></center>
                                        <button type="button" class="button-blue" v-b-modal="'gmap-modal'+index" style="width:100%">{{$t('Click here to point the location')}}</button>
                                        <!-- <button type="button" class="button-blue" @click="doCheckLocation(index)">Check Location</button> -->
                                    </div>
                                    <br>

                                    <div class="row">
                                        <div class="col-md">
                                            <div>{{$t('Address (Locality/Street) (Required)')}} <span style="font-size:12px;color:red;">({{$t('Required')}})</span></div>
                                            <input type="text" class="form-control input-address-delivery" :class="{ 'input-address-delivery--error': v.alamat.$error }" v-model="deliveryRoute.alamat" placeholder="ex: Jalan Kartini Raya" >
                                            <div v-if="v.alamat.$error">
                                                <div style="color:red;">{{$t('Address is required')}}</div>
                                            </div>
                                        </div>
                                        <div class="col-md">
                                            <div>{{$t('House/Floor/Unit Number (Required)')}} <span style="font-size:12px;color:red;">({{$t('Required')}})</span></div>
                                            <input type="text" class="form-control input-address-delivery" :class="{ 'input-address-delivery--error': v.apartemen.$error }" v-model="deliveryRoute.apartemen" placeholder="Ex: Lt 2 No.12" >
                                            <div v-if="v.apartemen.$error">
                                                <div style="color:red;">{{$t('House/Floor/Unit Number is required')}}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <br>

                                    <div class="row">
                                        <div class="col-md">
                                            <div>{{$t('State (Required)')}} <span style="font-size:12px;color:red;">({{$t('Required')}})</span></div>
                                            <select class="form-control selectbox-address-delivery" :class="{ 'input-address-delivery--error': v.provinsi.$error }" @change="getCity(deliveryRoute.provinsi, index)"  aria-label="Default select example" v-model="deliveryRoute.provinsi">
                                                <!-- <option value="Select State">Select State</option> -->
                                                <option v-for="dataProvice in dataProvinces" :key="dataProvice.id" :value="dataProvice.province">{{ dataProvice.province }}</option>
                                            </select>
                                            <div v-if="v.provinsi.$error">
                                                <div style="color:red;">{{$t('State is required')}}</div>
                                            </div>
                                        </div>
                                        <div class="col-md">
                                            <div>{{$t('City (Required)')}} <span style="font-size:12px;color:red;">({{$t('Required')}})</span></div>
                                            <select class="form-control selectbox-address-delivery" :class="{ 'input-address-delivery--error': v.kota.$error }"  aria-label="Default select example" v-model="deliveryRoute.kota">
                                                <!-- <option value="Select City">Select City</option> -->
                                                <option v-for="dataCity in deliveryRoute.dataCitys" :key="dataCity.id" :value="dataCity.city">{{ dataCity.city }}</option>
                                            </select>
                                            <div v-if="v.kota.$error">
                                                <div style="color:red;">{{$t('City is required')}}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <br>

                                    <!-- <div class="row">
                                        <div class="col-md">
                                            <div>House/Floor/Unit Number <span style="font-size:12px">(Required)</span></div>
                                            <input type="text" class="form-control input-address-delivery" :class="{ 'input-address-delivery--error': v.apartemen.$error }" v-model="deliveryRoute.apartemen" placeholder="Ex: Lt 2 No.12" >
                                            <div v-if="v.apartemen.$error">
                                                <div style="color:red;">House/Floor/Unit Number is required</div>
                                            </div>
                                        </div>
                                        <div class="col-md">
                                            <div>Postal Code <span style="font-size:12px">(Required)</span></div>
                                            <input type="text" class="form-control input-address-delivery" :class="{ 'input-address-delivery--error': v.kodePos.$error }" v-model="deliveryRoute.kodePos" placeholder="Ex: 16320" >
                                            <div v-if="v.kodePos.$error">
                                                <div style="color:red;">Postal Code is required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <br> -->

                                    <div class="row">
                                        <div class="col-md-6">
                                            <div>{{$t('Postal Code (Required)')}} <span style="font-size:12px;color:red;">({{$t('Required')}})</span></div>
                                            <input type="number" maxlength="5" class="form-control input-address-delivery" :class="{ 'input-address-delivery--error': v.kodePos.$error }" v-model="deliveryRoute.kodePos" placeholder="Ex: 16320" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);">
                                            <div v-if="v.kodePos.$error">
                                                <div style="color:red;">{{$t('Postal Code is required')}}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <br>

                                    <hr>

                                    <!-- <div class="row">
                                        <div class="col-md">
                                            <div>Latitude <span style="font-size:12px;color:red;">(Required)</span></div>
                                            <input type="text" class="form-control input-address-delivery" :class="{ 'input-address-delivery--error': v.latitude.$error }" v-model="deliveryRoute.latitude" placeholder="Ex: -6.4610789" >
                                            <div v-if="v.latitude.$error">
                                                <div style="color:red;">Latitude is required</div>
                                            </div>
                                        </div>
                                        <div class="col-md">
                                            <div>Longitude <span style="font-size:12px;color:red;">(Required)</span></div>
                                            <input type="text" class="form-control input-address-delivery" :class="{ 'input-address-delivery--error': v.longitude.$error }" v-model="deliveryRoute.longitude" placeholder="Ex: 106.8043952" >
                                            <div v-if="v.longitude.$error">
                                                <div style="color:red;">longitude is required</div>
                                            </div>
                                        </div>
                                    </div> -->
                                    <!-- <div class="click-link" v-b-modal="'gmap-modal'+index">
                                        Click here to check location on google maps
                                    </div> -->
                                    <br>

                                    <div class="row">
                                        <div class="col-md">
                                            <div>{{$t('Address Notes (Optional)')}} <span style="font-size:12px">({{$t('Optional')}})</span></div>
                                            <input type="text" class="form-control input-address-delivery" v-model="deliveryRoute.landmark" placeholder="Ex: Samping Indomaret Cimanggis" >
                                        </div>
                                    </div>
                                    <br>

                                    <div class="radio-toolbar">
                                        <input type="radio" v-model="deliveryRoute.isPriority" :id="'priority'+index" value="1" name="prioritySelected" @click="togglePrioriry(index)">
                                        <label :for="'priority'+index">{{$t('Make this address priority')}}</label> <p>{{$t('If selected, please ensure to click the “Calculate Shipping Cost” again')}}</p>
                                    </div>
                                    
                                </div>
                            </div>
                        </div> 
                        <b-modal :ref="'gmap-modal'+index"  :id="'gmap-modal'+index" size="xl" centered hide-footer hide-header>
                            <div class="">
                                <div id="gmapIframe">
                                    <!-- <iframe id="myFrame" :src="deliveryRoute.dataGmap" :set="deliveryRoute.dataGmap = (deliveryRoute.latitude != '' && deliveryRoute.longitude != '')?'https://maps.google.com/?q='+deliveryRoute.latitude+','+deliveryRoute.longitude+'&z=19&output=embed':'https://maps.google.com/?q=-6.1496277,106.8306063&z=19&output=embed'" allow="geolocation" height="490px" width="100%" title="Iframe Example"></iframe> -->
                                    <!-- <HereMap :center="center" @getLatitudeMaps="getLatitude($event)"  @getLongitudeMaps="getLongitude($event)" @yesClicked="getStreetAddressFrom()" /> -->
                                    <HereMap :center="deliveryRoute.center" @getLatitudeMaps="getLatitude($event, index)"
                                      @getLongitudeMaps="getLongitude($event, index)" @yesClicked="getStreetAddressFrom(index)" />
                                </div>
                                <div class="row">
                                    <div class="col-md">
                                        <div>{{$t('Input location (Optional)')}} <span style="font-size:12px">({{$t('Optional')}})</span></div>
                                        <!-- <gmap-autocomplete id="refDeliveryPoint" class="form-control input-address-delivery" @place_changed="getDataPickLocation($event, index)" placeholder="ex: Jl. Nusantara. Komp. Ruko Lokasari No. 7A Hankam"  /> -->
                                        <!-- <vue-bootstrap-typeahead 
                                            ref="typeahead"
                                            v-model="deliveryRoute.addressSearch"
                                            :data="deliveryRoute.addresses"
                                            :serializer="s => s.address.label"
                                            @hit="selectAutoSuggestHereMap($event, index)"
                                            @input="autoSuggestHereMap(deliveryRoute.addressSearch, index)"
                                            inputClass="form-control input-address-delivery"
                                            /> -->
                                        <vue-typeahead-bootstrap
                                            :data="deliveryRoute.addresses"
                                            v-model="deliveryRoute.addressSearch"
                                            :serializer="s => s.address.label"
                                            @hit="selectAutoSuggestHereMap($event, index)"
                                            @input="autoSuggestHereMap(deliveryRoute.addressSearch, index)"
                                            inputClass="form-control input-address-delivery"
                                            
                                            />
                                        <div class="row" style="margin-top:10px">
                                            <div class="col">
                                                <button class="button-blue-8 btn-lg left-in-phone" style="width:100%;margin-left:0px" @click="doPickLocation(index)"> {{$t('Pick Location')}}</button>
                                            </div>
                                        </div>
                                    </div>                                        
                                </div>                                    
                                <br>
                            </div>
                        </b-modal>
                    </li>  
                                        
                </ul>               
                <br/>
                <div v-if="services != 'Instant Delivery'">
                    <button class="button-blue-8 btn-lg left-in-phone" @click="addNewDeliveryRoute" > <b-icon-plus style="font-size:20px"/>{{$t('Add address')}}</button>
                </div>                

                <br><br>
                
                <div class="item-card-delivery-7">
                    
                    <b-form-checkbox v-model="checked" style="margin-left:0px" name="check-button" @change="toggleOptimize" switch>
                        <div><b>{{$t('Optimize route by Superkul System')}}</b></div>
                        <div>{{$t('Orders route will be optimized to get the most cost efficient rate')}}</div>
                    </b-form-checkbox>

                    <br><br>

                    
                    <div v-if="isLoadingCalculatePrice == true">
                        <img src="/icon/loading.gif" alt="" width="80px">
                    </div>
                    <div v-else>
                        <button class="button-blue-8 btn-lg" @click="doOptimizeRoute" style="margin-left:0px">{{$t('Calculate shipping costs')}}</button>
                    </div>
                    <div style="margin-top:15px">{{$t('Minimum 2KMs Per Order')}}</div>
                    <br><br>

                    <div>{{$t('Total Distance')}}</div>
                    <h3> {{totalJarak}}</h3>
                    <br>

                    <div>{{$t('Total Estimated Shipping Costs')}}</div>
                    <h3>Rp. {{totalCost}}</h3>

                    <br>

                    <div style="">
                        <center>
                            <vue-recaptcha @verify="onVerify" sitekey="6LfdadsaAAAAACVpLQmjsNZmC9LmB5tlO2Kv92zy" :loadRecaptchaScript="true">
                            </vue-recaptcha>    
                        </center>
                        
                    </div>

                    <br>

                    <div v-if="isLoadingCreateOrder">
                        <center>
                            <img src="/icon/loading.gif" alt="" width="80px">
                        </center>
                    </div>
                    <div v-else>
                        <div v-if="customer_type == 'prepaid'">
                            <button class="button-blue-8 btn-lg" @click="doOrderDeliver" style="margin-left:0px;width:100%">{{$t('Process to payment')}}</button>
                        </div>
                        <div v-else>
                            <button class="button-blue-8 btn-lg" @click="doOrderDeliver" style="margin-left:0px;width:100%">{{$t('Process Order Now')}}</button>
                        </div>
                        
                    </div>
                    
                </div>

                <br><br>

                <br><br>
            </div>
        </div>        
    </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import NavbarLogin from '@/components/Navbar_Login.vue'
import axios from 'axios'
import {HTTP} from '@/constants'
import { required } from "vuelidate/lib/validators";
import VueRecaptcha from 'vue-recaptcha';
// import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'
import HereMap from '@/components/HereMap.vue'
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap';

export default {
    name:"OrderDelivery",
    components: {
        Navbar,
        NavbarLogin,
        VueRecaptcha,   
        // VueBootstrapTypeahead ,
        HereMap,  
        VueTypeaheadBootstrap  
    },
    data() {
      return {
        checked: false,
        isLogin:false,
        isRobot:false,
        isLoadingCalculatePrice:false,
        isLoadingCreateOrder:false,
        minDate:"",
        maxDate:"",
        tokenUser:"",
        priorotyRoute:"",
        packageType:"",
        packageDescription:"",
        orderDescription:"",
        services:"Sameday",
        dateForm:"",
        timeForm:"",
        customer_type:"prepaid",
        temperaturMin:"",
        temperaturMax:"",
        totalCost:"0",
        totalJarak:"0 KM",
        dataProvinces:[],
        tempDeliveryRoutes:[],
        deliveryRoutes:[
            {
                formType:"pickup",
                alamat:"",
                nama:"",
                nomorTelepon:"",
                apartemen:"",
                landmark:"",
                kota:"",
                latitude:"",
                longitude:"",
                latlong:"",
                provinsi:"",
                labelAlamat:"",
                kodePos:"",
                dataCitys:[],
                dataGmap:"",
                dataLocation:{
                    address:{
                        label:""
                    },
                    position:{
                        lat:"",
                        lng:""
                    },
                },
                addresses:"",
                validatePhone:"",
                addressSearch:"",
                center:{ 
                    lat: -6.1496277, 
                    lng: 106.8306063,
                },
            },
            {
                formType:"deliver",  
                alamat:"",
                nama:"",
                nomorTelepon:"",
                apartemen:"",
                landmark:"",
                kota:"",
                latitude:"",
                longitude:"",
                latlong:"",
                provinsi:"",
                kodePos:"",
                isPriority:"0",
                deskripsiPesanan:this.orderDescription,
                labelAlamat:"",
                validatePhone:"",
                dataGmap: "",
                dataLocation:{
                    address:{
                        label:""
                    },
                    position:{
                        lat:"",
                        lng:""
                    },
                },
                addresses:"",
                dataCitys:[],
                addressSearch:"",
                center:{ 
                    lat: -6.1496277, 
                    lng: 106.8306063,
                },
            },
        ]
      }
    },
    validations:{
        services:{required},
        deliveryRoutes:{
            $each:{
                alamat:{ required },
                nama:{ required },
                nomorTelepon:{ required },
                apartemen:{ required },
                kodePos:{ required },
                kota:{required},
                provinsi:{required},
                latitude:{required},
                longitude:{required},
                validatePhone:{required},
            },
        }
    },
    mounted(){
        this.$refs.typeahead.inputValue = "test";
        const today = new Date();
        const date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
        //const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        var time = "";
        if(today.getMinutes() < 10){
            time = today.getHours() + ":0" + today.getMinutes();
        }else{
            time = today.getHours() + ":" + today.getMinutes();
        }
        
        this.dateForm = date;
        this.timeForm = time;
    },    
    created(){
        if(localStorage.getItem('token') != null){
            this.isLogin = true;
            this.tokenUser = localStorage.getItem('token');
            this.getDataCustomer();
        }else{
            this.isLogin = false;
        }

        if(localStorage.getItem("dataFormOrder") != null){
            var dataForm = JSON.parse(localStorage.getItem("dataFormOrder"));
            
            // console.log("masuk kok");
            // console.log(dataForm);

            this.services = dataForm.service;
            this.temperaturMin = dataForm.temperatur_min;
            this.temperaturMax = dataForm.temperatur_max;
            this.orderDescription = dataForm.order_description;
            this.deliveryRoutes = [];
            this.deliveryRoutes.push(dataForm.pickup);
            this.tempDeliveryRoutes.push(dataForm.pickup);
            dataForm.delivery.forEach((value)=>{
                this.deliveryRoutes.push(
                    value
                );
                this.tempDeliveryRoutes.push(
                    value
                )
            });
            this.checked = dataForm.isOptimize;
        }

        if(this.$route.query.deliveryAddress != null){
            this.deliveryRoutes[0].alamat = this.$route.query.pickupAddress;
            this.deliveryRoutes[0].latlong = this.$route.query.pickupLatLon;
            this.deliveryRoutes[0].latitude = this.$route.query.pickupLat;
            this.deliveryRoutes[0].longitude = this.$route.query.pickupLon;
            this.deliveryRoutes[1].alamat = this.$route.query.deliveryAddress;
            this.deliveryRoutes[1].latlong = this.$route.query.deliveryLatLon;
            this.deliveryRoutes[1].latitude = this.$route.query.deliveryLat;
            this.deliveryRoutes[1].longitude = this.$route.query.deliveryLon;

            //this.doOptimizeRoute();
        }

        this.getProvince();

        var currentDate = new Date()
        var day = currentDate.getDate()
        var monthNow = currentDate.getMonth() + 1
        var monthAfter = currentDate.getMonth() + 2
        var year = currentDate.getFullYear()

        this.minDate = year+"-"+monthNow+"-"+day;
        this.maxDate = year+"-"+monthAfter+"-"+day;
    },
    methods:{
        getLatitude(event, index){
            // console.log(event);
            this.deliveryRoutes[index].latitude = event;
            this.deliveryRoutes[index].dataLocation.position.lat = event;
            // console.log("--------------- latitude");
        },
        getLongitude(event, index){
            // console.log(event);
            this.deliveryRoutes[index].longitude = event;
            this.deliveryRoutes[index].dataLocation.position.lng = event;
            this.testLon = event;
            // console.log("----------------- longitude")
        },
        getStreetAddressFrom(index) {
            try {
                axios.get(
                    "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
                    this.deliveryRoutes[index].latitude +
                    "," +
                    this.deliveryRoutes[index].longitude +
                    "&key=AIzaSyCnPyYhvmSixevpGuHdNJ1T_G9OKRnbF-4"
                ).then((response)=> {
                    var data = response.data;
                    if (data.error_message) {
                        console.log(data.error_message)
                    } else {
                        console.log(data.results[0].formatted_address);
                        this.deliveryRoutes[index].addressSearch = data.results[0].formatted_address;
                        this.deliveryRoutes[index].addresses = [];
                        this.deliveryRoutes[index].dataLocation.address.label = data.results[0].formatted_address;
                        // this.address = data.results[0].formatted_address;
                    }
                }).catch((e)=>{
                    console.log(e);
                });
                
            } catch (error) {
                console.log(error.message);
            }
        },
        // clickMap($event){
        //     console.log($event);
        // },
        addNewDeliveryRoute(){
            this.deliveryRoutes.push({
                formType:"deliver",
                alamat:"",
                nama:"",
                nomorTelepon:"",
                apartemen:"",
                landmark:"",
                kota:"",
                provinsi:"",
                latitude:"",
                longitude:"",
                latlong:"",
                kodePos:"",
                isPriority:"0",
                deskripsiPesanan:this.orderDescription,
                labelAlamat:"",
                dataCitys:[],
                dataGmap:"",
                dataLocation:{
                    address:{
                        label:""
                    },
                    position:{
                        lat:"",
                        lng:""
                    },
                },
                addresses:"",
                validatePhone:"",
                addressSearch:"",
                center:{ 
                    lat: -6.1496277, 
                    lng: 106.8306063,
                },
            });
        },
        deleteDeliveryRoute(id){
            if(this.deliveryRoutes.length > 2 && this.deliveryRoutes[id].formType == "deliver"){
                this.deliveryRoutes.splice(id, 1);
            }          
        },
        formatPhoneNumber(phoneNumberString, el, id) {
            var elmnt = el.target;
            var div = document.createElement('div');

            var testdiv = '<div><div style="color:red;">'+this.$t("Format Phone Number invalid!")+'. '+this.$t("Ex")+': +6281234567890</div></div>'
            div.innerHTML = testdiv;
            var match =  /^(^\+628)\d{7,20}$/.test(phoneNumberString);
            if (match) {
                if(elmnt.nextElementSibling != null){
                    elmnt.nextElementSibling.remove();
                }
                this.deliveryRoutes[id].validatePhone = "1";
            }else{
                if(elmnt.nextElementSibling == null){
                    elmnt.parentNode.appendChild(div);
                }
                this.deliveryRoutes[id].validatePhone = "";
            }
            return null;            
        },
        formatDate(date_, el){
            var elmnt = el.target;
            var div = document.createElement('div');

            var testdiv = '<div><div style="color:red;">'+this.$t("Invalid Date")+'. '+this.$t("Ex")+': 04:00</div></div>'
            div.innerHTML = testdiv;
            var match =  /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(date_);
            if (match) {
                if(elmnt.nextElementSibling != null){
                    elmnt.nextElementSibling.remove();
                }
            }else{
                if(elmnt.nextElementSibling == null){
                    elmnt.parentNode.appendChild(div);
                }
            }
            return null;
        },
        togglePrioriry(id){
            console.log(id);
            this.deliveryRoutes[id].isPriority = "1";
            this.deliveryRoutes.forEach((value, index)=>{
                console.log(value);
                if(index != id && index != 0){
                    this.deliveryRoutes[index].isPriority = "0";
                }
            });
        },
        getPickupPoint(point, id){
            this.pickupPoint = point;
            console.log(point.formatted_address);
            console.log(point.name);
            console.log(point);
            console.log(id);
            this.deliveryRoutes[id].alamat = point.formatted_address;
            this.deliveryRoutes[id].latlong = point.geometry.location.lat()+","+point.geometry.location.lng();
            this.deliveryRoutes[id].longitude = point.geometry.location.lng();
            this.deliveryRoutes[id].latitude = point.geometry.location.lat();
            this.deliveryRoutes[id].dataGmap = 'https://maps.google.com/?q='+point.geometry.location.lat()+','+point.geometry.location.lng()+'&z=19&output=embed';

            let postcode = "";

            for (const component of point.address_components) {
                const componentType = component.types[0];

                switch (componentType) {
                case "postal_code": 
                    this.deliveryRoutes[id].kodePos = `${component.long_name}${postcode}`;
                    break;
                
                case "postal_code_suffix": 
                    this.deliveryRoutes[id].kodePos = `${postcode}-${component.long_name}`;
                    break;
                
                default:
                    this.deliveryRoutes[id].kodePos = "";
                    break;
                }
            }
            
        },
        getDeliverPoint(point, id){
            this.pickupPoint = point;
            console.log(point.formatted_address);
            console.log(point.name);
            console.log(point);
            console.log(id);
            this.deliveryRoutes[id].alamat = point.formatted_address;
            this.deliveryRoutes[id].latlong = point.geometry.location.lat()+","+point.geometry.location.lng();
            this.deliveryRoutes[id].longitude = point.geometry.location.lng();
            this.deliveryRoutes[id].latitude = point.geometry.location.lat();
            if(this.checked){
                this.tempDeliveryRoutes.push(this.deliveryRoutes[id]);
            }

            let postcode = "";

            for (const component of point.address_components) {
                const componentType = component.types[0];

                switch (componentType) {
                case "postal_code": 
                    this.deliveryRoutes[id].kodePos = `${component.long_name}${postcode}`;
                    break;
                
                case "postal_code_suffix": 
                    this.deliveryRoutes[id].kodePos = `${postcode}-${component.long_name}`;
                    break;
                
                default:
                    this.deliveryRoutes[id].kodePos = "";
                    break;
                }
            }
        },
        getDataPickLocation(point, id){
            console.log(point.formatted_address);
            console.log(point.name);
            console.log(point);
            console.log(id);
            console.log(point);
            console.log('https://maps.google.com/?q='+point.geometry.location.lat()+','+point.geometry.location.lng()+'&z=19&output=embed');
            this.deliveryRoutes[id].dataLocation = point;
            this.deliveryRoutes[id].dataGmap = 'https://maps.google.com/?q='+point.geometry.location.lat()+','+point.geometry.location.lng()+'&z=19&output=embed';
            this.deliveryRoutes[id].longitude = point.geometry.location.lng();
            this.deliveryRoutes[id].latitude = point.geometry.location.lat();
        },
        doPickLocation(id){
            // var point = this.deliveryRoutes[id].dataLocation;
            var data = this.deliveryRoutes[id].dataLocation;

            // this.deliveryRoutes[id].alamat = point.formatted_address;
            // this.deliveryRoutes[id].latlong = point.geometry.location.lat()+","+point.geometry.location.lng();
            // this.deliveryRoutes[id].longitude = point.geometry.location.lng();
            // this.deliveryRoutes[id].latitude = point.geometry.location.lat();
            
            // let postcode = "";

            // for (const component of point.address_components) {
            //     const componentType = component.types[0];

            //     switch (componentType) {
            //     case "postal_code": 
            //         this.deliveryRoutes[id].kodePos = `${component.long_name}${postcode}`;
            //         break;
                
            //     case "postal_code_suffix": 
            //         this.deliveryRoutes[id].kodePos = `${postcode}-${component.long_name}`;
            //         break;
                
            //     default:
            //         this.deliveryRoutes[id].kodePos = "";
            //         break;
            //     }
            // }

            this.deliveryRoutes[id].dataLocation = data;
            this.deliveryRoutes[id].lokasiPickup = data.address.label;
            this.deliveryRoutes[id].latlong = data.position.lat+","+data.position.lng;
            this.deliveryRoutes[id].longitude = data.position.lng;
            this.deliveryRoutes[id].latitude = data.position.lat;
            this.deliveryRoutes[id].alamat = data.address.label;
            this.deliveryRoutes[id].selectedAddress = data;
            // console.log("Masuukk guyss");
            // this.deliveryRoutes[id].dataGmap = 'https://maps.google.com/?q='+this.latitude+','+this.longitude+'&z=19&output=embed';
            // this.deliveryRoutes[id].dataGmap = 'https://maps.google.com/?q='+point.geometry.location.lat()+','+point.geometry.location.lng()+'&z=19&output=embed';

            this.$bvModal.hide('gmap-modal'+id);
            //this.$refs['gmap-modal'+id].hide();

        },
        toggleOptimize(){
            if(this.checked){
                this.tempDeliveryRoutes = this.deliveryRoutes;
                // console.log("checked true");
            }else{
                this.deliveryRoutes = this.tempDeliveryRoutes;
                // console.log("checked false");
            }
        },
        scrollToTop() {
                window.scrollTo(0,0);
        },
        onVerify: function (response) {
            if (response){
                this.isRobot = true;
            } else{
                this.isRobot = false;
            }
            // console.log(response);
        },
        checkTemperature(data, ket){

            if(data >= -22 && data <= 10 ){
                if(ket == "max"){
                    if(parseFloat(this.temperaturMin)  > parseFloat(this.temperaturMax)){
                        // console.log(this.temperaturMin > this.temperaturMax);
                        this.temperaturMax = 10;
                    }
                }
            }else{
                if(ket == "max"){
                    this.temperaturMax = 10;
                }else{
                    this.temperaturMin = -22;
                }
            }
        },
        dateDisabled(ymd, date) {
            // Disable weekends (Sunday = `0`, Saturday = `6`) and
            // disable days that fall on the 13th of the month
            const weekday = date.getDay()
            const day = date.getDate()
            // console.log(date.getMonth());
            const month = date.getMonth();
            // var monthh = date.getMonth();
            // Return `true` if the date should be disabled
            return month === 4 || weekday === 6 || day === 13
        },
        selectAutoSuggestHereMap(data, id){
            // this.pickupPoint = data;
            this.deliveryRoutes[id].dataLocation = data;
            // this.deliveryRoutes[id].lokasiPickup = data.address.label;
            this.deliveryRoutes[id].latlong = data.position.lat+","+data.position.lng;
            this.deliveryRoutes[id].longitude = data.position.lng;
            this.deliveryRoutes[id].latitude = data.position.lat;
            this.deliveryRoutes[id].center.lat = data.position.lat;
            this.deliveryRoutes[id].center.lng = data.position.lng;
            
            // this.deliveryRoutes[id].selectedAddress = data;
            // console.log("Masuukk guyss");
            this.deliveryRoutes[id].dataGmap = 'https://maps.google.com/?q='+this.latitude+','+this.longitude+'&z=19&output=embed';
            //this.deliveryRoutes[id].provinsi = point.address_components[point.address_components.length - 3].long_name

        },    

        // CONNECTION
        doOrderDeliver(){
            this.isLoadingCreateOrder = true;

            // validate time
            var match =  /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(this.timeForm);
            if (!match) {
                this.$toast.error(""+this.$t('Invalid Date')+". "+this.$t('Ex')+": 04:00", {
                    type:'error',
                    position:'top-right',
                    duration:3000,
                    dismissible:true
                });
                this.isLoadingCreateOrder = false;
                this.scrollToTop();
                return;
            }
            
            this.$v.$touch();
            console.log(this.$v);
            // stop here if form is invalid
            if (this.$v.$invalid){
                this.$toast.error(this.$t('Please Fill Required Form!'), {
                    type:'error',
                    position:'top-right',
                    duration:3000,
                    dismissible:true
                });
                this.isLoadingCreateOrder = false;
                this.scrollToTop();
                return;
            }

            if(!this.isRobot){
                this.$toast.error(this.$t('Check captcha first!'), {
                    type:'error',
                    position:'top-right',
                    duration:3000,
                    dismissible:true
                });
                this.isLoadingCreateOrder = false;
                return;
            }

            var deliveryData=[];
            this.deliveryRoutes.forEach((value, index)=>{
                if(index > 0){
                    deliveryData.push(
                        value
                    );
                }                
            });

            var data = JSON.stringify({
                "service":this.services,
                "temperatur_min":this.temperaturMin,
                "temperatur_max":this.temperaturMin,                
                "order_description":this.orderDescription,
                "pickup":this.deliveryRoutes[0],
                "delivery":deliveryData,
                "isOptimize":this.checked,
                "type":"webapps",
                "schedule":this.dateForm+" "+this.timeForm
            });
            
            console.log(data);
            localStorage.setItem('dataFormOrder', data);
            
            if(localStorage.getItem('token') == null){
                this.$toast.error(this.$t('Please log in to your account first!'), {
                    type:'error',
                    position:'top-right',
                    duration:3000,
                    dismissible:true
                });
                this.isLoadingCreateOrder = false;
            }else{
                this.$toast.warning(this.$t('Wait, on process!'), {
                    type:'warning',
                    position:'top-right',
                    duration:3000,
                    dismissible:true
                });

                axios.post(HTTP+"api/v1/create-order",
                    data
                ,{
                    headers:{
                        Authorization: "Bearer "+ this.tokenUser,
                        "Accept":"application/json",
                        "content-type": "application/json",  
                }
                }).then((response)=>{
                    this.isLoadingCreateOrder = false;
                    if(response.data.status == "success"){
                        localStorage.removeItem("dataFormOrder");
                        localStorage.setItem('payment', response.data.data);
                        this.$toast.success(response.data.message, {
                            type:'success',
                            position:'top-right',
                            duration:3000,
                            dismissible:true
                        });
                        
                        if(this.customer_type == "prepaid"){
                            this.$router.push("UserPayment");
                        }else{
                            this.$router.push("OrderHistory");
                        }
                        
                    }else{
                        this.$toast.error(response.data.message, {
                            type:'error',
                            position:'top-right',
                            duration:3000,
                            dismissible:true
                        });
                    }
                    console.log(response);
                }).catch((err)=>{
                    this.isLoadingCreateOrder = false;
                    console.log(err);
                });
            }            
        },
        doOptimizeRoute(){
            this.isLoadingCalculatePrice = true;
            this.$v.$touch();
            console.log(this.$v);
            
            // stop here if form is invalid
            if (this.$v.$invalid){
                this.$toast.error(this.$t('Please Fill Required Form!'), {
                    type:'error',
                    position:'top-right',
                    duration:3000,
                    dismissible:true
                });
                this.isLoadingCalculatePrice = false;
                this.scrollToTop();
                return;
            }
            
            this.$toast.warning(this.$t('Wait, on process!'), {
                    type:'warning',
                    position:'top-right',
                    duration:3000,
                    dismissible:true
                });

            var deliveryData=[];
            this.deliveryRoutes.forEach((value, index)=>{
                if(index > 0){
                    deliveryData.push(
                        value
                    );
                }                
            });
            
            var data = JSON.stringify({
                "service":this.services,
                "isOptimize":this.checked,
                "pickup":this.deliveryRoutes[0],
                "delivery":deliveryData,
                "trip_id":"9",
            });
            console.log(data);

            axios.post(HTTP+"api/v1/calculate-price",data,{
                headers:{
                 "Accept":"application/json",
                 "content-type": "application/json",  
                }}).then((response)=>{
                    this.isLoadingCalculatePrice = false;
                    this.$toast.success(this.$t('Success Calculate Cost!'), {
                            type:'success',
                            position:'top-right',
                            duration:3000,
                            dismissible:true
                        });

                    console.log(response);
                    this.deliveryRoutes = response.data.data;
                    this.totalJarak = response.data.planned_distance;
                    this.totalCost = response.data.total_rates;
                }).catch((e)=>{
                    this.isLoadingCalculatePrice = false;
                    console.log(e.getMessage());
                });
        },
        getProvince(){
            axios.post(HTTP+"api/v1/province-list").then((response)=>{
                if(response.data.status == "success"){
                    this.dataProvinces = response.data.data;
                }else{
                    this.$toast.error(response.data.message, {
                            type:'error',
                            position:'top-right',
                            duration:3000,
                            dismissible:true
                        });
                }
            }).catch((e)=>{
                console.log(e);
            });
        },
        getCity(data, index){
            axios.post(HTTP+"api/v1/city-list", {
                province:data
            }).then((response)=>{
                if(response.data.status == "success"){
                    this.deliveryRoutes[index].dataCitys = response.data.data;
                }else{
                    this.$toast.error(response.data.message, {
                            type:'error',
                            position:'top-right',
                            duration:3000,
                            dismissible:true
                        });
                }
            }).catch((e)=>{
                console.log(e);
            });
        },
        doCheckLocation(id){
            this.$toast.warning(this.$t('Wait, on process!'), {
                type:'warning',
                position:'top-right',
                duration:3000,
                dismissible:true
            });

            axios.post(HTTP+"api/v1/geocoding", {
                apartment:this.deliveryRoutes[id].apartment,
                streetName:this.deliveryRoutes[id].alamat,
                landmark:this.deliveryRoutes[id].landmark,
                city:this.deliveryRoutes[id].kota,
                state:this.deliveryRoutes[id].provinsi,
                postal_code:this.deliveryRoutes[id].kodePos,
            }).then((response)=>{
            if(response.data.status == "success"){
                this.$toast.success(response.data.message, {
                    type:'success',
                    position:'top-right',
                    duration:3000,
                    dismissible:true
                });
                
                this.deliveryRoutes[id].latitude = response.data.data.latitude;
                this.deliveryRoutes[id].longitude = response.data.data.longitude;
                this.deliveryRoutes[id].latlong = response.data.data.latitude+","+response.data.data.longitude;
                this.deliveryRoutes[id].dataGmap = 'https://maps.google.com/?q='+response.data.data.latitude+','+response.data.data.longitude+'&z=19&output=embed';

            }else{
                this.$toast.error(response.data.message, {
                    type:'error',
                    position:'top-right',
                    duration:3000,
                    dismissible:true
                });
            }
            }).catch((err)=>{
                console.log(err);
            });
        },
        getDataCustomer(){
            axios.post(HTTP+"api/v1/c-info", 
                {
                    reference_id:this.refId,
                },{
                    headers:{
                        "Authorization": "Bearer "+ localStorage.getItem("token")
                    }
                })
                .then((response)=>{
                    if(response.data.status == "success"){
                        this.deliveryRoutes[0].alamat = response.data.data.user_address[0].address;
                        this.deliveryRoutes[0].apartemen = response.data.data.user_address[0].apartment;
                        this.deliveryRoutes[0].kodePos = response.data.data.user_address[0].postal_code;
                        this.deliveryRoutes[0].provinsi = response.data.data.user_address[0].province;
                        this.deliveryRoutes[0].kota = response.data.data.user_address[0].city;
                        this.deliveryRoutes[0].latitude = response.data.data.user_address[0].latitude;
                        this.deliveryRoutes[0].longitude = response.data.data.user_address[0].longitude;
                        this.deliveryRoutes[0].latlong = response.data.data.user_address[0].latitude+","+response.data.data.user_address[0].longitude;
                        this.deliveryRoutes[0].nama = response.data.data.name;
                        this.deliveryRoutes[0].nomorTelepon = response.data.data.phone_number;
                        this.deliveryRoutes[0].validatePhone = "1";
                        this.temperaturMin = response.data.data.temperatur_min;
                        this.temperaturMax = response.data.data.temperatur_max;
                        this.customer_type = (response.data.data.customer_type == null)?"prepaid":response.data.data.customer_type;
                        if(this.deliveryRoutes[0].provinsi.length > 0){
                            this.getCity(this.deliveryRoutes[0].provinsi, 0);
                        }
                        
                    }else{
                        console.log('gagal anjir');
                    }
                    
                }).catch((err)=>{
                    console.log(err);
                });
        },
        async autoSuggestHereMap(query, id){
            axios.get("https://autosuggest.search.hereapi.com/v1/autosuggest?at=-2.4153279,108.8516932&limit=100&lang=id&q="+query+"&apiKey=SNj0BOytgEjBLygUZvdZr-nVdfUlq6eSELESmm72HLI")
            .then((response)=>{
                console.log(response.data);
                this.deliveryRoutes[id].addresses = response.data.items;
            }).catch((err)=>{
                console.log(err);
            });
        },
    }
}
</script>

<style>
    .orderDelivery{
        border-top: 2px solid lightgrey;
        width: 100%;
    }

    .radio-toolbar {
        margin: 6px;
    }

    .radio-toolbar input[type="radio"] {
        opacity: 0;
        position: fixed;
        width: 0;
    }

    .radio-toolbar label {
        display: inline-block;
        background-color: white;
        padding: 5px 20px;
        font-family: sans-serif, Arial;
        font-size: 16px;
        border: 2px solid #ddd;
        border-radius: 30px;
    }

    .radio-toolbar label.radio-disabled {
        display: inline-block;
        background-color: gray;
        padding: 5px 20px;
        font-family: sans-serif, Arial;
        font-size: 16px;
        border: 2px solid gray;
        border-radius: 30px;
    }

    .radio-toolbar label:hover {
        background-color: #046b8e80;
        border-color: #046b8e80;
    }

    .radio-toolbar label.radio-disabled:hover {
        background-color: gray;
        border-color: gray;
    }

    .radio-toolbar input[type="radio"]:focus + label {
        border: 2px dashed #444;
    }

    .radio-toolbar input[type="radio"]:checked + label {
        background-color: #046b8eff;
        border-color: #046b8eff;
        color: white;
    }

    .margin-left-20 {
        margin-left: 20px;
    }
    
</style>