<template>
  <div>
    <Navbar/>
    <div class="userinfo">
        <div class="container">
            <div class="row">
                <div class="col-md-3 d-none d-md-block">
                    <Sidebar/>
                </div>

                <div class="col ml-3">
                    <div class="mt-5 mr-3">
                        <div class="row ml-1">
                            <img src="/images/user-profil.png"  class="image-rounded" alt="Avatar">
                            <div class="image-text"> {{ inputFullName }} <!--<img src="/icon/arrow-down.png" class="image-text" >--></div>
                        </div>
                        
                        <div class="mt-5">
                            {{$t('User Profile')}}
                        </div>
                    
                        <div class="mt-3">
                            <div class="row">
                                <div class="col-md">
                                    <span>{{$t('Customer Name')}}</span>
                                    <input type="text" class="form-control input-form" v-model="inputName" :placeholder="$t('type here')">
                                </div>

                                <!-- <div class="col-md">
                                    <span>Full Name</span>
                                    <input type="text" class="form-control input-form" v-model="inputFullName" placeholder="type here" disabled>
                                </div> -->

                                <div class="col-md">
                                    <span>{{$t('PIC Name')}}</span>
                                    <input type="text" class="form-control input-form" v-model="inputPicName" :placeholder="$t('type here')">
                                </div>
                            </div>
                        </div>

                        <div class="mt-3">
                            <div class="row">
                                
                                <div class="col-md">
                                    <span>{{$t('Email')}}</span>
                                    <input type="text" class="form-control input-form" v-model="inputEmail" :placeholder="$t('type here')" disabled>
                                </div>

                                <div class="col-md">
                                    <span>{{$t('Phone Number')}}</span>
                                    <input type="text" class="form-control input-form" v-model="inputPhone" :placeholder="$t('type here')">
                                </div>
                            </div>
                        </div>

                        <!-- <div class="mt-3">
                            <div class="row">
                                <div class="col-md">
                                    <span>Location</span>
                                    <input type="text" class="form-control input-form" v-model="inputLocation" placeholder="type here" disabled>
                                </div>

                                <div class="col-md">
                                    <span>Postal Code</span>
                                    <input type="text" class="form-control input-form" v-model="inputPostalCode" placeholder="type here" disabled>
                                </div>
                            </div>
                        </div> -->

                        <div class="mt-3">
                            <div class="row">
                                <!-- <div class="col-md">
                                    <span>Phone Number</span>
                                    <input type="text" class="form-control input-form" v-model="inputPhone" placeholder="type here" disabled>
                                </div> -->

                                <div class="col-md-6">
                                    <span>{{$t('Password (if you would like to change)')}} <span style="font-size:12px;">({{$t('if you would like to change')}})</span></span>
                                    <input type="password" class="form-control input-form" v-model="inputPassword" :placeholder="$t('New Password')">
                                </div>
                            </div>
                        </div>

                        <div class="image-text mt-3">
                            <button type="button" class="button-form-blue" @click="doUpdateData">{{$t('Save Changes')}}</button>
                        </div>
                    </div>
                </div>
            </div>
            <br><br><br>
        </div>
    </div>
    <div class="d-md-none">
        <BotNavbar :page="'userinfo'"/>
    </div>
  </div>
</template>

<script>
import Sidebar from '@/components/Sidebar.vue'
import Navbar from '@/components/Navbar_Login.vue'
import BotNavbar from '@/components/BottomNavBar.vue'
import axios from "axios";
import {HTTP} from '@/constants'

export default {
    name:"UserInfo",
    components: {
        Sidebar,
        Navbar,
        BotNavbar
    },
    data(){
        return{
            refId:"",
            tokenUser:"",
            inputName:"",
            inputFullName:"",
            inputEmail:"",
            inputPhone:"",
            inputLocation:"",
            inputPostalCode:"",
            inputPassword:"",
            inputPicName:"",

        }
    },
    created(){
        if(localStorage.getItem('token') == null){
            this.$toast.error(this.$t('Please log in to your account first!'), {
                type:'error',
                position:'top-right',
                duration:3000,
                dismissible:true
            });
            this.$router.push("/");
        }else{
            this.refId = localStorage.getItem('refId');
            this.tokenUser = localStorage.getItem('token');
            this.getDataCustomer();
        }
    },methods:{
        getDataCustomer(){
            axios.post(HTTP+"api/v1/c-info", 
                {
                    reference_id:this.refId,
                },{
                    headers:{
                        "Authorization": "Bearer "+ localStorage.getItem("token")
                    }
                })
                .then((response)=>{
                    if(response.data.status == "success"){
                        this.inputName = response.data.data.name;
                        this.inputFullName = response.data.data.name;
                        this.inputEmail = response.data.data.email;
                        this.inputPhone = response.data.data.phone_number;
                        this.inputLocation = response.data.data.city;
                        this.inputPostalCode = response.data.data.postal_code;
                        this.inputPicName = response.data.data.user_address[0].pic_name;
                    }else{
                        console.log('gagal anjir');
                    }
                    
                }).catch((err)=>{
                    console.log(err);
                });
        },
        doChangePassword(){
            this.$toast.warning(this.$t('Wait, on process!'), {
                type:'warning',
                position:'top-right',
                duration:3000,
                dismissible:true
            });

            axios.post(HTTP+"api/v1/change-password", {
                new_password:this.inputPassword,
            },{
                headers:{
                    Authorization: "Bearer "+this.tokenUser
                }
            }).then((response)=>{
            if(response.data.status == "success"){
                this.$toast.success(response.data.message, {
                    type:'success',
                    position:'top-right',
                    duration:3000,
                    dismissible:true
                });

            }else{
                this.$toast.error(response.data.message, {
                    type:'error',
                    position:'top-right',
                    duration:3000,
                    dismissible:true
                });
            }
            }).catch((err)=>{
                console.log(err);
            });
        },
        doUpdateData(){
            this.$toast.warning(this.$t('Wait, on process!'), {
                type:'warning',
                position:'top-right',
                duration:3000,
                dismissible:true
            });

            axios.post(HTTP+"api/v1/update-c-info", {
                pic_name: this.inputPicName,
                password: this.inputPassword,
                phone_number:this.inputPhone,
                name:this.inputName,
            },{
                headers:{
                    Authorization: "Bearer "+this.tokenUser
                }
            }).then((response)=>{
            if(response.data.status == "success"){
                this.$toast.success(response.data.message, {
                    type:'success',
                    position:'top-right',
                    duration:3000,
                    dismissible:true
                });

                localStorage.setItem('name', this.inputName);
                this.$router.push("UserInfo");
                this.$router.go(0);
            }else{
                this.$toast.error(response.data.message, {
                    type:'error',
                    position:'top-right',
                    duration:3000,
                    dismissible:true
                });
            }
            }).catch((err)=>{
                console.log(err);
            });
        }        
    }
}
</script>

<style scoped>
.userinfo{
    border-top: 2px solid rgb(240, 240, 240);
}


</style>