<template>
    <div>
        <Navbar/>
        <div class="orderHistory">
            <div class="container">
                <div class="row">
                    <div class="col-md-3 d-none d-md-block">
                        <Sidebar/>
                    </div>
                    <div class="col mr-3 ml-3">
                        <div class="row">
                            <div class="col-md-6">
                                <br><br>
                                <h4>Order Items History</h4>
                                <br>
                                <div v-if="isLoading">
                                    <center>
                                        <br>
                                        <img src="/icon/loading.gif" alt="">
                                        <h3>Loading...</h3>
                                    </center>                    
                                </div>
                                <div v-else>
                                    <div v-for="dataDelivery in dataDeliverys" :key="dataDelivery.id">
                                        <ItemOrderHistory :items="dataDelivery"/>
                                    </div>
                                </div><br>

                                <h4>Tracking</h4>
                                <br>
                                <ul class="progressbar itemorder">
                                    <li>
                                        <div class="content-order-detail">
                                            <div class="row">
                                                <div class="col">
                                                    <b>Order Created</b><br/>
                                                    <span>{{ convertDate(datas.created_at) }}</span>
                                                </div>                                            
                                            </div>
                                        </div> 
                                    </li>
                                    <div v-if="datas.order_data_raw.type == 'api'">

                                    </div>
                                    <div v-else>
                                        <li>
                                            <div class="content-order-detail">
                                                <div class="row">
                                                    <div class="col">
                                                        <b>Payment Status</b><br/>
                                                        <div v-if="statusPayment == 'expired'">
                                                            EXPIRED
                                                        </div>
                                                        <div v-else>
                                                            <span>{{ checkStatusPayment(datas.payment_status) }} {{ datas.payment_date }}</span>
                                                        </div>
                                                    </div>                                            
                                                </div>
                                            </div> 
                                        </li>
                                    </div>                                    
                                    <li v-for="dataDelivery in dataDeliverys" :key="dataDelivery.id">
                                        <div class="content-order-detail">
                                            <div class="row">
                                                <div class="col">
                                                    <b>Order {{dataDelivery.deliver_details.deliverAccountName}}</b><br/>
                                                    <span>{{dataDelivery.order_no}}</span><br>
                                                    <span>{{ (dataDelivery.order_trip_name == null)? "-":dataDelivery.order_trip_name}}</span><br>
                                                    <b><span>{{dataDelivery.distance}} KM</span></b><br>
                                                    <b><span>Rp. {{calculateCharge(dataDelivery)}}</span></b>
                                                </div>                                            
                                            </div>
                                        </div> 
                                    </li>
                                    <li>
                                        <div class="content-order-detail">
                                            <div class="row">
                                                <div class="col">
                                                    <b>Trip End</b><br/>
                                                    <span>-</span>
                                                </div>                                            
                                            </div>
                                        </div> 
                                    </li>
                                    
                                </ul>
                            </div>
                            <div class="col-md-6">
                                <br><br>
                                <div v-if="datas.order_data_raw.type == 'api'">
                                    <div class = "shipping-cost-history">
                                        <div class="row">
                                            <div class="col">
                                                <div>AWB Number</div>
                                                <h4> {{ datas.awb_number }}</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <br>
                                    <div class = "shipping-cost-history">
                                        <div class="row">
                                            <div class="col">
                                                <div>Total Distance</div>
                                                <h3> {{ datas.distance }} KM</h3>
                                            </div>
                                        </div>
                                        <br>
                                        <div class="row">
                                            <div class="col">
                                                <div>Total Shipping Costs</div>
                                                <h3>Rp. {{ formatRupiah(datas.rates) }}</h3>
                                                <button class="button-green-8 btn-lg" style="margin-left:0px;width:100%" disabled> <b-icon icon="credit-card" aria-hidden="true"></b-icon> H2H</button>
                                            </div>
                                        </div>                        
                                    </div>
                                </div>
                                <div v-else>
                                    <div v-if="statusOrder == 'Failed'">
                                        <div class = "shipping-cost-history">
                                            <div class="row">
                                                <div class="col">
                                                    <div>AWB Number</div>
                                                    <h4> {{ datas.awb_number }}</h4>
                                                </div>
                                            </div>
                                        </div>
                                        <br>
                                        <div class = "shipping-cost-history">
                                            <div class="row">
                                                <div class="col">
                                                    <div>Total Distance</div>
                                                    <h3> {{ datas.distance }} KM</h3>
                                                </div>
                                            </div>
                                            <br>
                                            <div class="row">
                                                <div class="col">
                                                    <div>Total Shipping Costs</div>
                                                    <h3>Rp. {{ formatRupiah(datas.rates) }}</h3>
                                                    <button class="button-grey-8 btn-lg" style="margin-left:0px;width:100%" disabled> <b-icon icon="credit-card" aria-hidden="true"></b-icon> Failed </button>
                                                </div>
                                            </div>                        
                                        </div>
                                    </div>
                                    <div v-else>
                                        <div v-if="statusPayment == 'pending'">
                                            <div class = "shipping-cost-history">
                                                <div class="row">
                                                    <div class="col">
                                                        <div>AWB Number</div>
                                                        <h4> {{ datas.awb_number }}</h4>
                                                    </div>
                                                </div>
                                            </div>
                                            <br>
                                            <div class = "shipping-cost-history">
                                                <div class="row">
                                                    <div class="col">
                                                        <div>Total Distance</div>
                                                        <h3> {{ datas.distance }} KM</h3>
                                                    </div>
                                                </div>
                                                <br>
                                                <div class="row">
                                                    <div class="col">
                                                        <div>Total Shipping Costs</div>
                                                        <h3>Rp. {{ formatRupiah(datas.rates) }}</h3>
                                                        <button class="button-blue-8 btn-lg" style="margin-left:0px;width:100%" @click="goToPaynow"> <b-icon icon="credit-card" aria-hidden="true"></b-icon> Pay Now</button>
                                                    </div>
                                                </div>                        
                                            </div>
                                        </div>
                                        <div v-else-if="statusPayment == 'expired'">
                                            <div class = "shipping-cost-history">
                                                <div class="row">
                                                    <div class="col">
                                                        <div>AWB Number</div>
                                                        <h4> {{ datas.awb_number }}</h4>
                                                    </div>
                                                </div>
                                            </div>
                                            <br>
                                            <div class = "shipping-cost-history">
                                                <div class="row">
                                                    <div class="col">
                                                        <div>Total Distance</div>
                                                        <h3> {{ datas.distance }} KM</h3>
                                                    </div>
                                                </div>
                                                <br>
                                                <div class="row">
                                                    <div class="col">
                                                        <div>Total Shipping Costs</div>
                                                        <h3>Rp. {{ formatRupiah(datas.rates) }}</h3>
                                                        <button class="button-grey-8 btn-lg" style="margin-left:0px;width:100%" disabled> <b-icon icon="credit-card" aria-hidden="true"></b-icon> Expired</button>
                                                    </div>
                                                </div>                        
                                            </div>
                                        </div>
                                        <div v-if="statusPayment == 'success'">
                                            <div class = "shipping-cost-history">
                                                <div class="row">
                                                    <div class="col">
                                                        <div>AWB Number</div>
                                                        <h4> {{ datas.awb_number }}</h4>
                                                    </div>
                                                </div>
                                            </div>
                                            <br>
                                            <div class = "shipping-cost-history">
                                                <div class="row">
                                                    <div class="col">
                                                        <div>Total Distance</div>
                                                        <h3> {{ datas.distance }} KM</h3>
                                                    </div>
                                                </div>
                                                <br>
                                                <div class="row">
                                                    <div class="col">
                                                        <div>Total Shipping Costs</div>
                                                        <h3>Rp. {{ formatRupiah(datas.rates) }}</h3>
                                                        <button class="button-green-8 btn-lg" style="margin-left:0px;width:100%" disabled> <b-icon icon="credit-card" aria-hidden="true"></b-icon> Paid</button>
                                                    </div>
                                                </div>                        
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                                <br>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br><br>
        </div>
        <div class="d-md-none">
            <BotNavbar :page="'history'"/>
        </div>
    </div>
</template>

<script>
import ItemOrderHistory from '@/components/Items.vue'
import Sidebar from '@/components/Sidebar.vue'
import Navbar from '@/components/Navbar_Login.vue'
import BotNavbar from '@/components/BottomNavBar.vue'
import axios from 'axios'
import {HTTP} from '@/constants'

export default {
    name:"OrderItems",
    components:{
        ItemOrderHistory,
        Sidebar,
        Navbar,
        BotNavbar
    },
    data(){
        return{
            refId:"",
            tokenUser:"",
            isLoading:true,
            dataKey:"OrderItem",
            dataDeliverys:[],
            datas:"",
            dataorders:[],
            statusPayment:"",
            statusOrder:"",          
        }
    },
    created(){
        if(localStorage.getItem('token') == null){
            this.$toast.error(this.$t('Please log in to your account first!'), {
                type:'error',
                position:'top-right',
                duration:3000,
                dismissible:true
            });
            this.$router.push("/");
        }else{
            this.refId = localStorage.getItem('refId');
            this.tokenUser = localStorage.getItem('token');
            var data = JSON.parse(localStorage.getItem('dataOrder'));
            console.log(data);
            this.datas = data;
            this.dataDeliverys = data.order_items;
            this.statusOrder = data.status;
            this.checkPayment();
        }
    },
    mounted(){
        this.isLoading = true;
        axios.post(HTTP+"api/v1/get-order",{},{
            headers:{
                "Authorization":"Bearer "+this.tokenUser
            }
        }).then((response)=>{
            this.isLoading = false;
            this.dataorders = response.data.data;
        }).catch((err)=>{
            this.isLoading = false;
            console.log(err);
        });
    },
    methods:{
      convertDate(date){
            var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear(),
            hours = d.getHours(),
            minute = d.getMinutes();

            if (month.length < 2) 
                month = '0' + month;
            if (day.length < 2) 
                day = '0' + day;

            return [year, month, day].join('-') + " "+ [hours, minute].join(":")+":00";
      },
      checkStatusPayment(data){
            var message="";
            switch(data){
                case "PENDING":
                     message = "Waiting for payment";
                    break;
                case "APPROVED":
                    message = "Paid";
                    break;
                case "REJECTED":
                    message = "Failed";
                    break;
                case "EXPIRED":
                    message = "Waiting for payment";
                    break;
                default:
                    message = "Waiting for payment";
            }

            return message;
        },
        checkPayment(){
            const dates = this.convertDate(this.datas.payment_url_expired_date);
            const today = new Date(new Date().toString().split('GMT')[0]+' UTC').toISOString().split('.')[0].replace('T',' ');

            // console.log(dates+"");
            // console.log(today+"");
            console.log("testt"+   today > dates);
            
            if(this.datas.payment_status == "PENDING"){
                if(today > dates){
                    this.statusPayment = "expired";
                }else{
                    this.statusPayment = "pending";
                }
            }else if(this.datas.payment_status == "APPROVED") {
                this.statusPayment = "success";
            }else{
                this.statusPayment = "failed";
            }
            
        },
        formatRupiah(angka, prefix){
            var number_string = angka.replace(/[^,\d]/g, '').toString(),
            split   		= number_string.split(','),
            sisa     		= split[0].length % 3,
            rupiah     		= split[0].substr(0, sisa),
            separator       = "",
            ribuan     		= split[0].substr(sisa).match(/\d{3}/gi);
        
            // tambahkan titik jika yang di input sudah menjadi angka ribuan
            if(ribuan){
                separator = sisa ? '.' : '';
                rupiah += separator + ribuan.join('.');
            }
        
            rupiah = split[1] != undefined ? rupiah + ',' + split[1] : rupiah;
            return prefix == undefined ? rupiah : (rupiah ? 'Rp. ' + rupiah : '');
        },
        goToPaynow(){
            localStorage.setItem('payment', this.datas.payment_url);
            this.$router.push('UserPayment');
        },
        calculateCharge(data){
            var distance_charge = parseInt(data.distance_charge);
            var handling_charge = parseInt(data.handling_charge);
            var pickup_charge = parseInt(data.pickup_charge);
            var holiday_charge = parseInt(data.holiday_charge);

            var results = distance_charge + handling_charge + pickup_charge + holiday_charge;

            return results;
        }
  }
    
}
</script>

<style>
    .orderHistory{
        border-top: 2px solid lightgrey;
    }
</style>