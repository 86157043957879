<template>
    <div>
        <div v-if="isLogin">
            <NavbarLogin/>
        </div>

        <div v-else>
            <Navbar/>
        </div>

        <iframe id="myFrame" :src="linkPayment" allow="geolocation" height="683px" width="100%" title="Iframe Example"></iframe>
    </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import NavbarLogin from '@/components/Navbar_Login.vue'

export default {
    name:"UserPayment",
    components: {
        Navbar,
        NavbarLogin,
    },
    data(){
        return{
            linkPayment:"",
            isLogin:false,
        }
    },
    created(){
        if(localStorage.getItem('token') != null){
            this.isLogin = true;
            this.tokenUser = localStorage.getItem('token');
            var link = localStorage.getItem("payment");
            this.linkPayment = link;
        }else{
            this.isLogin = false;
            this.$router.push("/");
        }
    }
}
</script>

<style>

</style>