<template>
    <div class="card-order-history" @click="goToItemDetail">
        <div class="row">
            <div class="col-md " style="margin-left:5px">
                <div style="float:left;" class="mr-3">
                    <img src="/icon/ic_success.png" alt="">
                </div>
                <div class="font-mid">
                    <b>ORDER to {{items.deliver_details.deliverAccountName}} </b><br/>
                    <span>{{items.order_no}}</span><br>
                    <!-- <span class="d-md-none">{{ checkStatusPayment(dataOrder.payment_status) }}</span> -->
                </div>
            </div>
            <!-- <div class="col d-none d-md-block d-lg-block mr-3 mt-2" >
                <span class="d-flex flex-row-reverse">
                    
                </span>
            </div> -->
        </div>
    </div>
</template>

<script>
export default {
    name:"Items",
    props:['items'],
    methods:{
        goToItemDetail(){
            var data = JSON.stringify(this.items);
            localStorage.setItem('dataItems', data);
            // this.$router.push({path:"OrderDetail", query:{
            //     dataOrder: this.dataOrder,
            // }});
            this.$router.push('OrderItemDetail');
        },
        checkStatusPayment(data){
            var message="";
            switch(data){
                case "PENDING":
                     message = "Waiting for payment";
                    break;
                case "APPROVED":
                    message = "Paid";
                    break;
                case "REJECTED":
                    message = "Failed";
                    break;
                case "EXPIRED":
                    message = "Waiting for payment";
                    break;
                default:
                    message = "Waiting for payment";
            }

            return message;
        },
        convertDate(date){
            var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear(),
            hours = d.getHours(),
            minute = d.getMinutes();

            if (month.length < 2) 
                month = '0' + month;
            if (day.length < 2) 
                day = '0' + day;

            return [year, month, day].join('-') + " "+ [hours, minute].join(":");
      }
    }
}
</script>

<style>
    .card-order-history{
        border: 1px solid;
        padding: 8px;
        border-radius: 50px;
        justify-content: center;
        align-items: center;
        border-color: #808080ff;
        margin-top: 10px;
        cursor: pointer;
    }

    .text-right{
        text-align: right;
    }

    .bg-blue{
        background-color: aqua;
    }
</style>